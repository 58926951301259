import React, {useEffect, useState} from 'react';
import BigMap from '../../components/maps/BigMap';
import HeaderLoggedIn from '../../components/headers/headerLoggedIn';
import Filters from '../../components/containers/mapPage/Filters';

import OpenFormButton from "../../components/buttons/OpenFormButton";
import NewKumForm from '../../components/forms/addToMap/NewKumForm';
import NewNodeForm from '../../components/forms/addToMap/NewNodeForm';
import NewPipeForm from '../../components/forms/addToMap/NewPipeForm';
import NewHomeownerAssocForm from "../../components/forms/addToMap/NewHomeownerAssocForm";
import NewCableForm from "../../components/forms/addToMap/NewCableForm";

import apiUrls from "../../configs/api";
import Search from "../../components/inputsSelectsCheckboxes/search/Search";
import Post from "../../functions/apiCalls/Post/Post";
import HandleNotification from "../../functions/notifications/HandleNotification";
import GetById from "../../functions/apiCalls/Get/GetById";
import GetUserType from '../../functions/apiCalls/Get/GetUserType';
import { initialLayerFilters } from '../../functions/filter/layerFilters';
import ToggleButton from "../../components/buttons/ToggleButton";
import SearchForAddress from "../../components/inputsSelectsCheckboxes/search/SearchForAddress";
import HomeownerAssocsNetadmin from "../../functions/fetches/HomeownerAssocsNetadmin";
import SearchAdvisor from "../../components/inputsSelectsCheckboxes/search/SearchAdvisor";
import HelpAdvisor from "../../components/containers/mapPage/HelpAdvisor";
import Help from "../../components/containers/mapPage/Help";

const MapPage = () => {
    const [userType, setUserType] = useState(null);
    const token = sessionStorage.getItem('token');
    const [selectedForm, setSelectedForm] = useState('newKum');
    const [layerFilters, setLayerFilters] = useState(initialLayerFilters);
    const [fetchedData, setFetchedData] = useState({
        kummer: [],
        noder: [],
        cables: [],
        pipes: [],
        homeownerAssocs: [],
        netadminHomeownerAssocs: []
    });
    const [databaseUpdateCounter, setDatabaseUpdateCounter] = useState(0);
    const [endpoint, setEndpoint] = useState(null);

    const [isAddingPoint, setIsAddingPoint] = useState(false);
    const [isAddingLine, setIsAddingLine] = useState(false);
    const [isAddingPoly, setIsAddingPoly] = useState(false);
    const [clickCoordinates, setClickCoordinates] = useState([]);
    const [snappedNode, setSnappedNode] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(null);

    const [zoomLevel, setZoomLevel] = useState(13);
    const [hoveredResult, setHoveredResult] = useState(null);
    const [mapCenter, setMapCenter] = useState([59.91273, 10.74609]);

    const [searchAddress, setSearchAddress] = useState(false);
    const [isAddressSearch, setIsAddressSearch] = useState(false);

    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
            if (userType.includes("Read Only")) {
                document.body.style.overflow = 'hidden';
            }
        };
        fetchUserType();
    }, []);

    useEffect(() => {
        const fetchData = async (endpoints) => {
            try {
                const responses = await Promise.all(
                    endpoints.map(endpoint => fetch(endpoint, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }))
                );
                const data = await Promise.all(
                    responses.map(response => response.json())
                );
                const responsesOk = responses.every(response => response.ok);
                if (responsesOk) {
                    const [
                        kummerData,
                        noderData,
                        cablesData,
                        pipesData,
                        homeownerAssocsData
                    ] = data;

                    setFetchedData({
                        kummer: kummerData,
                        noder: noderData,
                        cables: cablesData,
                        pipes: pipesData,
                        homeownerAssocs: homeownerAssocsData
                    });
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error during fetch:', error);
            }
        };
        const endpoints = Object.values(apiUrls.getForMap);
        fetchData(endpoints);
    }, [databaseUpdateCounter]);

    useEffect(() => {
        const fetchNetadmin = async () => {
            try {
                await HomeownerAssocsNetadmin(
                    (netadmin) => {setFetchedData((prevState) => ({
                        ...prevState,
                        netadminHomeownerAssocs: netadmin
                    }));
                    },
                    null
                );
            }
            catch (error) {
                console.error('Error during fetch:', error);
            }
        }
        if (userType === "Rådgiver Read Only" || selectedForm === 'newHomeownerAssoc') {
            fetchNetadmin();
        }
    }, [selectedForm]);

    const handleResultHover = (result) => {
        setHoveredResult(result);
    };

    const handleFormSelection = (formType) => {
        setSelectedForm((prevSelectedForm) => {
            setIsAddingPoint(false);
            setIsAddingLine(false);
            setIsAddingPoly(false);
            return prevSelectedForm === formType ? null : formType;
        });
    };

    const handlePost = async (data, apiUrl) => {
        try {
            let response = await Post(apiUrl, data);
            if (response.ok) {
                const responseData = await response.json();
                const newObjectId = responseData.id;
                if (newObjectId) {
                    const trimmedUrl = apiUrl.replace("/create", "");
                    const newlyCreatedObject = await GetById(trimmedUrl, newObjectId);
                    if (newlyCreatedObject) {
                        await HandleNotification(newlyCreatedObject, 'La til nytt objekt', newlyCreatedObject.properties ? newlyCreatedObject.properties.name : newlyCreatedObject.name);
                    } else {
                        console.error('Failed to fetch newly created object');
                    }
                } else {
                    console.error('Failed to get object ID from response data');
                }
                setEndpoint(endpoint);
                setSelectedForm(null);
                setIsAddingPoint(false);
                setIsAddingLine(false);
                setIsAddingPoly(false);
                setDatabaseUpdateCounter(prevCounter => prevCounter + 1);
            } else {
                console.error('Failed to post data:', response.statusText);
            }
        } catch (error) {
            console.error('Error during posting:', error);
        }
    };

    return (
        <div>
            <HeaderLoggedIn/>
            <div className="map flex--row">
                <div className="map--filter">
                    <div className="p-30px">
                        <div className="map--filter-comps flex--column">
                            <div className="flex--row justify-content-sb align-items--center map--filter-toggle">
                                <h3>Søk etter {searchAddress ? 'adresse' : (userType !== 'Rådgiver Read Only' ? 'objekt' : 'borettslag')}</h3>
                                <ToggleButton setSearch={setSearchAddress}/>
                            </div>
                            {searchAddress ? (
                                <SearchForAddress
                                    setMapCenter={setMapCenter}
                                    setIsAddressSearch={setIsAddressSearch}
                                    setZoom={setZoomLevel}
                                />
                            ) :
                                userType !== "Rådgiver Read Only" ? (
                                    <Search
                                        onResultHover={handleResultHover}
                                    />
                                ) : (
                                    <SearchAdvisor
                                        homeownerAssocData={fetchedData.homeownerAssocs}
                                        netadminData={fetchedData.netadminHomeownerAssocs}
                                        onResultHover={handleResultHover}
                                        setMapCenter={setMapCenter}
                                    />
                                )
                            }
                            {userType !== "Rådgiver Read Only" ? (
                                <>
                                    <Filters
                                        filters={layerFilters}
                                        setFilters={setLayerFilters}
                                        fetchedData={fetchedData}
                                        onFilterChange={setLayerFilters}
                                    />
                                    <Help/>
                                </>
                                
                            ) : (
                                <HelpAdvisor/>
                            )}
                        </div>
                    </div>
                </div>
                <BigMap
                    key={JSON.stringify(fetchedData)}
                    mapCenter={mapCenter}
                    setMapCenter={setMapCenter}
                    zoom={zoomLevel}
                    zoomChange={setZoomLevel}
                    layerFilters={layerFilters}
                    isAddingPoint={isAddingPoint}
                    isAddingLine={isAddingLine}
                    isAddingPoly={isAddingPoly}
                    clickedCoordinate={clickCoordinates}
                    setClickedCoordinate={setClickCoordinates}
                    selectedForm={selectedForm}
                    setSelectedForm={setSelectedForm}
                    fetchedData={fetchedData}
                    setDatabaseUpdateCounter={setDatabaseUpdateCounter}
                    hoveredResult={hoveredResult}
                    setHoveredResult={setHoveredResult}
                    isAddressSearch={isAddressSearch}
                    setIsAddressSearch={setIsAddressSearch}
                    userType={userType}
                    setSnappedNode={setSnappedNode}
                    currentPosition={currentPosition}
                    setCurrentPosition={setCurrentPosition}
                />
            </div>
            {userType && !userType.includes("Read Only") && (
                <div className="map--add flex--row">
                    <div className="map--add-nav w-25vw flex--column align-items--center justify-content-center">
                        <h3>Opprett Nytt Objekt</h3>
                        <div className="map--add-nav-buttons flex--column justify-content-sb align-items--center">
                            <OpenFormButton
                                formType="newKum"
                                selectedForm={selectedForm}
                                onClick={handleFormSelection}
                                label="Tilkoblingspunkt"
                            />
                            <OpenFormButton
                                formType="newNode"
                                selectedForm={selectedForm}
                                onClick={handleFormSelection}
                                label="Node"
                            />
                            <OpenFormButton
                                formType="newCable"
                                selectedForm={selectedForm}
                                onClick={handleFormSelection}
                                label="Kabel"
                            />
                            <OpenFormButton
                                formType="newPipe"
                                selectedForm={selectedForm}
                                onClick={handleFormSelection}
                                label="Rør"
                            />
                            <OpenFormButton
                                formType="newHomeownerAssoc"
                                selectedForm={selectedForm}
                                onClick={handleFormSelection}
                                label="Borettslag"
                            />
                        </div>
                    </div>
                    <div className="w-75vw">
                        {selectedForm === null && (
                            <div className="map--no-add-form flex--column">
                                <h3>Velg objekt å legge til</h3>
                                <p>Bruk menyen til venstre for å velge og legge til et objekt på kartet.</p>
                            </div>
                        )}
                        {selectedForm === 'newKum' &&
                            <NewKumForm
                                onSubmit={(data) => handlePost(data, apiUrls.post.createKum)}
                                isAddingKum={isAddingPoint}
                                setIsAddingKum={setIsAddingPoint}
                                pointCoordinate={clickCoordinates}
                                setPointCoordinate={setClickCoordinates}
                                currentPosition={currentPosition}
                                setCurrentPosition={setCurrentPosition}
                            />}
                        {selectedForm === 'newNode' &&
                            <NewNodeForm
                                onSubmit={(data) => handlePost(data, apiUrls.post.createNode)}
                                isAddingNode={isAddingPoint}
                                setIsAddingNode={setIsAddingPoint}
                                pointCoordinate={clickCoordinates}
                                setPointCoordinate={setClickCoordinates}
                                currentPosition={currentPosition}
                                setCurrentPosition={setCurrentPosition}
                            />}
                        {selectedForm === 'newCable' && (
                            <NewCableForm
                                onSubmit={(data) => handlePost(data, apiUrls.post.createCable)}
                                isAddingCable={isAddingLine}
                                setIsAddingCable={setIsAddingLine}
                                drawnCoordinates={clickCoordinates}
                                setDrawnCoordinates={setClickCoordinates}
                                snappedNode={snappedNode}
                                setSnappedNode={setSnappedNode}
                            />
                        )}
                        {selectedForm === 'newPipe' && (
                            <NewPipeForm
                                onSubmit={(data) => handlePost(data, apiUrls.post.createPipe)}
                                isAddingPipe={isAddingLine}
                                setIsAddingPipe={setIsAddingLine}
                                drawnCoordinates={clickCoordinates}
                                setDrawnCoordinates={setClickCoordinates}
                            />
                        )}
                        {selectedForm === 'newHomeownerAssoc' && (
                            <NewHomeownerAssocForm
                                onSubmit={(data) => handlePost(data, apiUrls.post.createHomeownerAssoc)}
                                isAddingHomeownerAssoc={isAddingPoly}
                                setIsAddingHomeownerAssoc={setIsAddingPoly}
                                drawnCoordinates={clickCoordinates}
                                setDrawnCoordinates={setClickCoordinates}
                                netadminHomeownerAssocs={fetchedData.netadminHomeownerAssocs}
                            />
                        )}
                    </div>
                </div>
            )}
            <div id="bottom"></div>
        </div>
    );
};
export default MapPage;