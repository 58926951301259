import React, {useEffect, useRef, useState} from "react";
import { LayerGroup, GeoJSON, Marker, CircleMarker, Popup} from "react-leaflet";
import DrawPolygons from "./DrawPolys";
import findMarkerCoordinate from "../../../functions/map/FindMarkerCoordinate";
import AllRenderFilter from "../../../functions/filter/filterRenders/AllRenderFilter";
import PolyRenderFilter from "../../../functions/filter/filterRenders/PolyRenderFilter";
import ProcessCoordinates from "../../../functions/map/ProcessCoordinates";
import UpdatingObject from "./UpdatingObject";
import HoverPopupClick from "../../../functions/map/HoverPopupClick";

const RenderPolygons = ({ data, style, radius, mapIcons, PopupComponent, handleUpdateCoordinates, isUpdatingPolygon, updatingId, getUpdatedCoordinates, snapMarker, drawingIcon, hoveredResult, layerFilters, isAddingPoint, isAddingLine, isAddingPoly, isUpdatingPoint, setSnapMessage }) => {
    const [draggedCoordinates, setDraggedCoordinates] = useState([]);
    const markerRef = useRef(null);
    const hoveredId = hoveredResult && hoveredResult.id;
    const isAdding = isAddingPoint || isAddingLine || isAddingPoly || isUpdatingPolygon || isUpdatingPoint;
    const showIcon = (item) => {
        return updatingId !== item.id && layerFilters.all.icons && layerFilters.homeownerAssocs.icons;
    }
    const isUpdating = (item) => {
        return isUpdatingPolygon && updatingId === item.id;
    }
    const getIcon = (item) => {
        return item.properties.netadminData ? mapIcons.netadminIcon : mapIcons.defaultIcon;
    }

    useEffect(() => {
        if (isUpdatingPolygon) {
            const updatedCoordinates = data.find((item) => item.id === updatingId)?.geometry.coordinates[0].map(coord => [coord[1], coord[0]]) || [];
            setDraggedCoordinates(updatedCoordinates);
            getUpdatedCoordinates(updatedCoordinates);
        }
    }, [updatingId, data, isUpdatingPolygon]);

    useEffect(() => {
        HoverPopupClick(hoveredResult, markerRef);
    }, [hoveredResult]);

    const handleDrawCoordinates = (drawnCoordinates) => {
        setDraggedCoordinates(drawnCoordinates);
        getUpdatedCoordinates(drawnCoordinates);
    }

    const handleMarkerDragEnd = (index, event) => {
        const { target } = event;
        if (!target || !target._latlng) return;
        let { lat, lng } = target._latlng;
        let minDistance = { value: Number.MAX_VALUE };
        let closestMarker = { value: null };
        const snapDistanceThreshold = 0.00005;

        data.forEach(dataItem => {
            if (dataItem.id === updatingId) {
                return;
            }
            if (!dataItem.geometry || !dataItem.geometry.coordinates) {
                console.log(`No valid geometry found for data object.`);
                return;
            }
            const { coordinates } = dataItem.geometry;
            coordinates.forEach(ring => ProcessCoordinates(ring, minDistance, snapDistanceThreshold, closestMarker, lat, lng, dataItem));
        });
        if (closestMarker.value) {
            const snappedLat = closestMarker.value.coordinates.latitude;
            const snappedLng = closestMarker.value.coordinates.longitude;
            target.setLatLng([snappedLat, snappedLng]);
            const snapMarkerData = closestMarker.value.data ? closestMarker.value.data.properties : closestMarker.value;
            setSnapMessage(`Snappet til borettslaget ${snapMarkerData.name}`);
            lat = snappedLat;
            lng = snappedLng;
        }
        const newCoordinates = [...draggedCoordinates];
        if (target.options.isMidMarker) {
            newCoordinates.splice(index, 0, [lat, lng]);
        } else {
            newCoordinates[index] = [lat, lng];
        }
        setDraggedCoordinates(newCoordinates);
        getUpdatedCoordinates(newCoordinates);
    };

    const filteredData = data.filter((item) => {
        const allRenderResult = AllRenderFilter(layerFilters, item);
        const polyRenderResult = PolyRenderFilter(layerFilters, item);

        return allRenderResult && polyRenderResult;
    });

    return (
        <LayerGroup>
            {filteredData.map((item, index) => (
                <React.Fragment key={item.id}>
                    {item.geometry.coordinates[0].length > 1 ? (
                        isUpdatingPolygon && updatingId === item.id ? (
                            <UpdatingObject
                                draggedCoordinates={draggedCoordinates}
                                setDraggedCoordinates={setDraggedCoordinates}
                                handleUpdatedCoordinates={getUpdatedCoordinates}
                                handleMarkerDragEnd={handleMarkerDragEnd}
                                type="polygon"
                                style={style}
                            />
                        ) : (
                            <>
                                <GeoJSON
                                    data={item}
                                    style={style}
                                    item={item}
                                    ref={hoveredId === item.id ? markerRef : null}
                                >
                                    {!isAdding && (
                                        <Popup position={findMarkerCoordinate(item)}>
                                            <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                        </Popup>
                                    )}
                                </GeoJSON>

                                {showIcon(item) && (
                                    <Marker position={findMarkerCoordinate(item)} icon={getIcon(item)}>
                                        {!isAdding && (
                                            <Popup position={findMarkerCoordinate(item)}>
                                                <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                            </Popup>
                                        )}
                                    </Marker>
                                )}
                            </>
                        )
                    ) : item.geometry.coordinates[0].length === 1 ? (
                        isUpdating(item) ? (
                            <DrawPolygons
                                key={index}
                                color={style.color}
                                firstCoordinateIcon={getIcon(item)}
                                isAdding={isUpdatingPolygon && updatingId === item.id}
                                drawingIcon={drawingIcon}
                                snapMarker={snapMarker}
                                isUpdating={true}
                                handleDrawCoordinates={handleDrawCoordinates}
                                data={data}
                            />
                        ) : (
                            <CircleMarker
                                key={`circle-${item.id}`}
                                center={findMarkerCoordinate(item)}
                                radius={radius}
                                color={style.color}
                                fillColor={style.fillColor}
                                fillOpacity={style.fillOpacity}
                                weight={style.weight}
                                ref={hoveredId === item.id ? markerRef : null}
                            >
                                {!isAdding && (
                                    <Popup position={findMarkerCoordinate(item)}>
                                        <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                    </Popup>
                                )}
                            </CircleMarker>
                        )
                    ) : null}
                </React.Fragment>
            ))}

            {filteredData.map((item) => (
                showIcon(item) ? (
                    <Marker
                        key={`marker-${item.id}`}
                        position={findMarkerCoordinate(item)}
                        icon={getIcon(item)}
                        item={item}
                    >
                        {!isAdding && (
                            <Popup position={findMarkerCoordinate(item)}>
                                <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                            </Popup>
                        )}
                    </Marker>
                ) : null
            ))}
        </LayerGroup>
    );
};

export default RenderPolygons;




