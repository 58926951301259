import React, {useEffect, useRef} from "react";
import {Marker, Popup, LayerGroup, GeoJSON} from "react-leaflet";
import findMarkerCoordinate from "../../../functions/map/FindMarkerCoordinate";
import PointRenderFilter from "../../../functions/filter/filterRenders/PointRenderFilter";
import AllRenderFilter from "../../../functions/filter/filterRenders/AllRenderFilter";
import mapMarkers from "../../iconsAndStyles/MapMarkers";
import FindIfFullNode from "../../../functions/page-spesific/infoPage/FindIfFullNode";
import HoverPopupClick from "../../../functions/map/HoverPopupClick";

const RenderPoints = ({ data, icons, movingIcon, newIcon, isAddingPoint, isAddingLine, isAddingPoly, clickedCoordinate, PopupComponent, handleUpdateCoordinates, isUpdatingPoint, setIsUpdatingPoint, updatingId, setUpdatingId, hoveredResult, layerFilters, isUpdatingLine, selectedForm, currentPosition, setCurrentPosition }) => {
    const markerRef = useRef(null);
    const hoveredId = hoveredResult && hoveredResult.id;
    const isAdding = isAddingLine || isAddingPoint || isAddingPoly;
    const addingPoint = isAddingPoint && (currentPosition || clickedCoordinate );
    const drawingLine = isAddingLine || isUpdatingLine;

    const regularIcon = (item) => {
        if (item.type === "Node") {
            return item.nodeType === "Dist" ? icons.distIcon : icons.defaultIcon;
        }
        if (item.type === "Kum") {
            return (item.kumType.includes("Veggskap") || item.kumType === "Skjøteboks") ? icons.wallIcon : icons.defaultIcon;
        }
    };
    const updatingIcon = (item) => {
        if (item.type === "Node") {
            return item.nodeType === "Dist" ? movingIcon.distMovingIcon : movingIcon.accessMovingIcon;
        }
        if (item.type === "Kum") {
            return (item.kumType ===  "Veggskap (Innvendig)" || item.kumType === "Veggskap (Utvendig)") ? movingIcon.kumWallMovingIcon : movingIcon.kumMovingIcon;
        }
    };

    const markerVisible = (item) => {
        return selectedForm === '' && isUpdatingPoint && updatingId === item.id && ((clickedCoordinate && clickedCoordinate.length === 2) || currentPosition);
    }

    const getIcon = (item) => {
        if (item.type === 'Node' && FindIfFullNode(item)){
            if (item.nodeType === 'Aksess') {
                return mapMarkers.nodeAccessFull;
            } else {
                return mapMarkers.nodeDistFull;
            }
        }
        else {
            return regularIcon(item);
        }
    }

    useEffect(() => {
        if (clickedCoordinate) {
            setCurrentPosition(null);
        }
    }, [clickedCoordinate]);

    useEffect(() => {
        HoverPopupClick(hoveredResult, markerRef);
    }, [hoveredResult]);

    useEffect(() => {
        if (selectedForm !== '' && updatingId) {
            setUpdatingId(null);
            setIsUpdatingPoint(null);
        }
    }, [selectedForm])

    const filteredData = data.filter((item) => {
        const allRenderResult = AllRenderFilter(layerFilters, item);
        const pointRenderResult = PointRenderFilter(layerFilters, item);
        if (hoveredId === item.id) {
            return true;
        }
        return allRenderResult && pointRenderResult;
    });

    return (
        <LayerGroup>
            {addingPoint && (
                <Marker key="adding-point" position={currentPosition ?? clickedCoordinate} icon={newIcon} />
            )}

            {filteredData.map((item) => (
                <React.Fragment key={item.id}>
                    {updatingId !== item.id && (
                        <>
                            <GeoJSON
                                data={{
                                    type: 'Feature',
                                    properties: {},
                                    geometry: {
                                        type: 'LineString',
                                        coordinates: [
                                            [item.coordinates.longitude, item.coordinates.latitude]
                                        ]
                                    },
                                    id: item.id
                                }}
                                ref={hoveredId === item.id ? markerRef : null}
                            >
                                {!isAdding && (
                                    <Popup position={findMarkerCoordinate(item)}>
                                        <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                    </Popup>
                                )}
                            </GeoJSON>

                            <Marker
                                position={findMarkerCoordinate(item)}
                                icon={drawingLine ? getIcon(item) : regularIcon(item)}
                            >
                                {!isAdding && (
                                    <Popup position={findMarkerCoordinate(item)}>
                                        <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                    </Popup>
                                )}
                            </Marker>
                        </>
                    )}

                    {markerVisible(item) && (
                        <Marker
                            position={currentPosition ?? clickedCoordinate}
                            icon={updatingIcon(item)}
                        />
                    )}
                </React.Fragment>
            ))}
        </LayerGroup>
    );
};

export default RenderPoints;








