import React, {useState} from "react";

const CustomSelectAddRemoveNodes = ({searchInput, setSearchInput, firstState, removeList, addList, setFirstList, setSelectedInput}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            setSelectedInput(null);
            setSearchInput("");
            setFirstList(addList);
        }
    };

    const handleSelect = (port) => {
        setSelectedInput(port);
        setSearchInput(port.label);
    };

    const handleSearchInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        setSearchInput(input); // Update the search input state

        // Filter the list based on `firstState`
        const filtered = firstState
            ? addList.filter((port) => port.label.toLowerCase().includes(input)) // If `firstState` is true, filter `firstListData`
            : removeList.filter((port) => port.label.toLowerCase().includes(input)); // Otherwise, filter `removeList`

        setFirstList(filtered); // Update the state of filtered list (this will be passed to the component)
    };

    console.log(addList[1]);
    console.log(firstState && addList[1].taken);

    return (
        <div className="custom-select flex--row align-items--center">
            <input
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
                onFocus={() => {
                    setShowDropdown(true);
                    setFirstList(firstState ? addList : removeList);
                }}
                onBlur={() => setTimeout(() => setShowDropdown(false), 1)}
                onKeyDown={handleKeyDown}
                placeholder="Søk etter port..."
                className="search-input"
            />
            {showDropdown && (
                <div className="dropdown-list">
                    {(firstState ? addList : removeList)
                        .sort((a, b) => {
                            const aValue = parseInt(a.value.split(',')[1], 10);
                            const bValue = parseInt(b.value.split(',')[1], 10);
                            return aValue - bValue;
                        })
                        .map((item, index) => (
                        <p
                            key={index}
                            className={`dropdown-item ${(firstState && item.taken === true) ? "disabled" : ""}`}
                            onMouseDown={() => {
                                if (!firstState || (firstState && !item.taken)) {
                                    handleSelect(item);
                                }
                            }}
                        >
                            {item.label}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
}
export default CustomSelectAddRemoveNodes;