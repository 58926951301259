import obosLogo from "../../assets/images/OBOSNetLogo.png";
import spotsLogo from "../../assets/images/spotsLogos/spotsLogo2.png";
import VersionInfo from './VersionInfo';
import {useEffect, useState} from "react";
import spotsLogoChristmas from "../../assets/images/spotsLogos/spotsLogoChristmas.png";
import IsDecember from "../../functions/checks/IsDecember";
import GetAll from "../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../configs/api";
import GetUserType from "../../functions/apiCalls/Get/GetUserType";
import IsEaster from "../../functions/checks/IsEaster";
import spotsLogoEaster from "../../assets/images/spotsLogos/spotsLogoEaster.png";
import IsRamadan from "../../functions/checks/IsRamadan";
import spotsLogoRamadan from "../../assets/images/spotsLogos/spotsLogoRamadan.png";
import IsSummer from "../../functions/checks/IsSummer";
import spotsLogoSummer from "../../assets/images/spotsLogos/spotsLogoSummer.png";

const HeaderLoggedIn = (fixed) => {
    const [logo, setLogo] = useState(spotsLogo);
    const [userType, setUserType] = useState(null);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

    useEffect(() => {
        if (IsDecember()) {
            setLogo(spotsLogoChristmas);
        }
        else if (IsEaster()) {
            setLogo(spotsLogoEaster);
        }
        else if (IsRamadan()) {
            setLogo(spotsLogoRamadan)
        }
        else if (IsSummer()) {
            setLogo(spotsLogoSummer)
        }
        else {
            setLogo(spotsLogo);
        }
        const getUserType = async () => {
            try {
                const type = await GetUserType();
                if (type) {
                    setUserType(type);
                }
            } catch (error) {
                console.error('Error fetching user type:', error);
            }
        };
        getUserType();
    }, []);

    useEffect(() => {
        const checkUnreadMessages = async () => {
            const unread = await GetAll(apiUrls.chat.checkUnreadMessages);
            if (unread) {
                setHasUnreadMessages(unread);
            }
        }
        checkUnreadMessages();
    }, []);

    function handleLogout() {
        sessionStorage.clear();
        window.location.href = '/';
    }

    return (
        <header className={`header flex--row justify-content-sb align-items--center h-10vh`}>
            <div className="header--logo flex--row">
                <img src={obosLogo} alt="Obos opennet logo" className="header--obos-logo"/>
                <div className="flex--row header--spots align-items--center justify-content-center">
                    <img src={logo} alt="Spots Logo" className="header--spots-logo"/>
                    <div className="flex--column align-items--center justify-content-center">
                        <h3>SPOTS</h3>
                        <p><VersionInfo/></p>
                    </div>
                </div>
            </div>
            <div className="header--buttons flex--row justify-content-sb align-items--center">
                {userType !== "Rådgiver Read Only" && (
                    <>
                        <a href="/kart">
                            Kart
                            <svg className="icon--compass"/>
                        </a>
                        <a href="/liste">
                            Liste <svg className="icon--list"/>
                        </a>
                        <a href="/chat">
                            Chat <svg className={`icon--chat ${hasUnreadMessages ? '-unread' : ''}`}/>
                        </a>
                        <a href="/profil">
                            Profil <svg className="icon--profile"/>
                        </a>
                    </>
                )}
                <button className={`btn btn-primary-white-bg ${userType === "Rådgiver Read Only" ? "m-r-0" : ""}`} onClick={handleLogout}>Logg ut</button>
            </div>
        </header>
    );
};

export default HeaderLoggedIn;
