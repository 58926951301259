import React, {useEffect} from "react";

const CustomAlert = ({message, setMessage}) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage(null);
        }, 5000);
        return () => clearTimeout(timer);
    }, [message]);

    return (
        <div className="custom-alert flex--row align-items--center justify-content-center">
            <p>{message}</p>
        </div>
    )
}
export default CustomAlert;