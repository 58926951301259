import React, {useEffect, useState} from "react";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../configs/api";
import GetById from "../../../functions/apiCalls/Get/GetById";
import InfoPageLog from "../../containers/infoPage/InfoPageLog";
import InfoPageData from "../../containers/infoPage/InfoPageData";
import {calculateLineLength} from "../../../functions/page-spesific/infoPage/getDistanceInMeters";
import InfoPageFiles from "../../containers/infoPage/InfoPageFiles";
import InfoPageConnectionsCable from "../../containers/infoPage/InfoPageConnectionsCable";
import LockedMessage from "../../containers/infoPage/LockedMessage";

const InfoPageCableContents = ({cable, userType}) => {
    const [cableLength, setCableLength] = useState(0);
    const [kummer, setKummer] = useState([]);
    const [node, setNode] = useState(null);
    const [pipe, setPipe] = useState(null);
    const [updatedPorts, setUpdatedPorts] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (cable && cable.properties && cable.properties.kummer && cable.properties.kummer.length > 0) {
                    const cableKumIds = cable.properties.kummer.map(cableKum => cableKum.pointId);
                    const allKummer = await GetAll(apiUrls.get.kummer);
                    const filteredKummer = allKummer.filter(kum =>
                        cableKumIds.includes(kum.id)
                    );
                    setKummer(filteredKummer);
                }

                if (cable && cable.properties && cable.properties.nodeId) {
                    const node = await GetById(apiUrls.get.nodes, cable.properties.nodeId);
                    setNode(node);
                }
                if (cable && cable.properties && cable.properties.pipeId) {
                    const pipe = await GetById(apiUrls.get.pipes, cable.properties.pipeId);
                    setPipe(pipe);
                }
            } catch (error) {
                console.error('Error fetching cable data:', error);
            }
        };
        fetchData();
    }, [cable, updatedPorts]);

    useEffect(() => {
        if (cable && cable.geometry && cable.geometry.coordinates && cable.geometry.coordinates.length > 0) {
            const length = calculateLineLength(cable.geometry.coordinates);
            setCableLength(length);
        }
    }, [cable]);

    return (
        <div className="info-page--content-data">
            {cable.properties.editLock && (
                <LockedMessage type="kabelen" lockerId={cable.properties.editorLockUserId}/>
            )}
            <div className="flex--row justify-content-sb">
                <InfoPageData
                    margin={false}
                    title="Info"
                    headers={["Status", "Type", "Lengde", "Start Koordinat", "Slutt Koordinat", "Notater"]}
                    data={[
                        cable.properties.status,
                        cable.properties.cableType,
                        `${cableLength.toFixed(0)} meter`,
                        `${cable.geometry.coordinates[0][1]}, ${cable.geometry.coordinates[0][0]}`,
                        `${cable.geometry.coordinates[cable.geometry.coordinates.length - 1][1]}, ${cable.geometry.coordinates[cable.geometry.coordinates.length - 1][0]}`,
                        cable.properties.notes ? cable.properties.notes : 'Ingen notater'
                    ]}
                />
                <InfoPageFiles
                    item={cable}
                    hasImages={false}
                    editLock={cable.properties.editLock}
                    editorLockUserId={cable.properties.editorLockUserId}
                    DefaultImage={null}
                    userType={userType}
                />
            </div>

            <InfoPageConnectionsCable
                item={cable}
                type="cable"
                pipe={pipe}
                connectionPoints={kummer}
                node={node}
                userType={userType}
                setUpdatedPorts={setUpdatedPorts}
            />

            <InfoPageLog item={cable}/>
        </div>
    );
}
export default InfoPageCableContents;