const ProcessCoordinates = (coords, minDistanceRef, snapDistanceThreshold, closestMarkerRef, lat, lng, data) => {
    coords.forEach(coord => {
        if (Array.isArray(coord) && coord.length === 2) {
            const [markerLng, markerLat] = coord;
            const distance = Math.sqrt(Math.pow(markerLat - lat, 2) + Math.pow(markerLng - lng, 2));

            if (distance <= snapDistanceThreshold && distance < minDistanceRef.value) {
                minDistanceRef.value = distance;
                closestMarkerRef.value = { coordinates: { latitude: markerLat, longitude: markerLng }, data };
            }
        }
    });
};
export default ProcessCoordinates;