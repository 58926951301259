import { useParams } from 'react-router-dom';
import HeaderLoggedIn from "../../../components/headers/headerLoggedIn";
import React, {useEffect, useState} from "react";
import GetById from "../../../functions/apiCalls/Get/GetById";
import apiUrls from "../../../configs/api";
import MiniMap from "../../../components/maps/MiniMap";
import InfoPageHeader from "../../../components/headers/InfoPageHeader";
import styles from "../../../components/iconsAndStyles/Styles";
import InfoPageCableContents from "../../../components/displays/info-pages/InfoPageCableContents";
import InfoPageEditHeader from "../../../components/inputsSelectsCheckboxes/InfoPageEditHeader";
import InfoPageEditCableContents from "../../../components/displays/info-pages/edit/InfoPageEditCableContents";
import HeaderButtons from "../../../components/buttons/HeaderButtons";
import To404 from "../../../functions/redirects/To404";
import GetUserType from '../../../functions/apiCalls/Get/GetUserType';

const CableInfoPage = () => {
    const { id } = useParams();
    const userId = sessionStorage.getItem('userId');
    const [cable, setCableData] = useState(null);
    const [editLock, setEditLock] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState('');
    const cableStyle = styles['cable'];
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
        };
        fetchUserType();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const data = await GetById(apiUrls.get.cables, id);
                    if (data) {
                        setCableData(data);
                        setEditLock(data.properties.editLock);
                    }
                    else {
                        To404();
                    }
                }
            } catch (error) {
                console.error('Error fetching cable data:', error);
            }
        };
        fetchData();
    }, [id, editMode, editLock]);

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, []);

    return (
        <div>
            {cable && (
                <>
                    <HeaderLoggedIn/>
                    <div className="info-page flex--row">
                        <div className="info-page--map">
                            <MiniMap marker={cable} icon="cable" style={cableStyle} zoom={17}/>
                        </div>
                        <div className="info-page--content flex--column w-78vw">
                            <div className="info-page--content-header flex--row justify-content-sb align-items--center">
                                {cable.properties && (
                                    !editMode ? (
                                        <InfoPageHeader
                                            name={cable.properties.name}
                                            icon="cable"
                                        />
                                    ) : (
                                        <InfoPageEditHeader
                                            name={cable.properties.name}
                                            icon="cable"
                                            setNewName={setNewName}
                                        />
                                    )
                                )}
                                {userType !== 'Read Only' && (
                                    <HeaderButtons
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                        editLock={editLock}
                                        setEditLock={setEditLock}
                                        userId={userId}
                                        userType={userType}
                                        item={cable}
                                        apiUrlLock={apiUrls.put.updateEditLockCable}
                                        apiUrlDelete={apiUrls.delete.deleteCable}
                                    />
                                )}
                            </div>
                            {!editMode ? (
                                <InfoPageCableContents cable={cable} userType={userType}/>
                            ) : (
                                <InfoPageEditCableContents cable={cable} newName={newName} setEditMode={setEditMode}/>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default CableInfoPage;