import getAllPorts from "../extractors/getAllPorts";
import getAvailablePorts from "../extractors/getAvailablePorts";

const getName = (id, list, message) => {
    if (!id) {
        return message;
    }
    if (!Array.isArray(id)) {
        const matchingObject = list.find((object) => object.id === id);
        return matchingObject ? (matchingObject.properties ? matchingObject.properties.name : matchingObject.name) : message;
    }
    const names = id.map((itemId) => {
        const matchingObject = list.find((object) => object.id === itemId);
        return matchingObject ? (matchingObject.properties ? matchingObject.properties.name : matchingObject.name) : null;
    }).filter(Boolean);
    return names.length > 0 ? names.join(", ") : message;
};

const FormatListObjects = (data, externalList1, externalList2, externalList3) => {
    if (!data) return [];
    const array = Array.isArray(data) ? data : [data];
    return array
        .filter(obj => obj)
        .map(obj => {
            const type = obj.properties?.type || obj.type;
            switch (type) {
                case "Node":
                    return {
                        ...obj,
                        allPorts: getAllPorts(obj),
                        availablePorts: getAvailablePorts(obj),
                    };
                case "Rør":
                    return {
                        ...obj,
                        pipeName: obj.properties?.pipeInPipe ? getName(obj.properties.pipeInPipeId, data, "Nei") : "Nei",
                        isFilled: obj.properties.filled ? "Ja" : "Nei",
                        cablesAmount: (obj.properties.cables && obj.properties.cables.length > 0) ? obj.properties.cables.length : 0,
                        pipesAmount: (obj.properties.pipes && obj.properties.pipes.length > 0) ? obj.properties.pipes.length : 0,
                    };
                case "Kabel":
                    return {
                        ...obj,
                        inPipe: externalList1 && getName(obj.properties.pipeId, externalList1, "Nei"),
                        nodes: externalList2 && getName(obj.properties.nodeId, externalList2, "Nei"),
                        connectionPoints: obj && obj.properties.kummer && obj.properties.kummer.length > 0 ?
                            (getName(obj.properties.kummer.map(kum => kum.pointId), externalList3, "Ingen tilkoblet")
                            ) : (
                                "Ingen tilkoblet"
                            )
                    };
                case "Borettslag":
                    return {
                        ...obj,
                        nodes: obj && obj.properties.nodes && Array.isArray(obj.properties.nodes) && obj.properties.nodes.length > 0 ? (
                            getName(obj.properties.nodes.map(node => node.pointId), externalList1, "Ingen noder tilkoblet")
                        ) : (
                            "Ingen noder tilkoblet"
                        )
                    }
                default:
                    return obj;
            }
        });
};
export default FormatListObjects;