import icons from "../../iconsAndStyles/Icons";
import {Marker, Polygon, Polyline} from "react-leaflet";
import React from "react";

const UpdatingObject = ({draggedCoordinates, setDraggedCoordinates, handleUpdatedCoordinates, handleMarkerDragEnd, type, style}) => {

    const markerEventHandlers = (index) => ({
        dragend: (event) => {
            handleMarkerDragEnd(index, event);
        },
        contextmenu: (event) => {
            if (event.originalEvent?.shiftKey && draggedCoordinates.length > 2) {
                handleDeleteCoordinate(index);
            }
        }
    });

    const handleDeleteCoordinate = (index) => {
        setDraggedCoordinates((prevCoords) => {
            const updatedCoords = prevCoords.filter((_, i) => i !== index);
            return [...updatedCoords];
        });
        setTimeout(() => {
            handleUpdatedCoordinates(draggedCoordinates.filter((_, i) => i !== index));
        }, 0);
    };

    return (
        <>
            {type === "line" ? (
                <Polyline
                    positions={draggedCoordinates}
                    pathOptions={style}
                />
            ) : (
                <Polygon
                    positions={draggedCoordinates}
                    pathOptions={style}
                />
            )}
            {draggedCoordinates.map((coord, index) => (
                <Marker
                    key={index}
                    position={coord}
                    draggable={true}
                    eventHandlers={markerEventHandlers(index)}
                    icon={icons["eachCoordinate"]}
                />
            ))}
            {draggedCoordinates.slice(1).map((coord, index) => (
                <Marker
                    key={`mid-${index}`}
                    position={[
                        (coord[0] + draggedCoordinates[index][0]) / 2,
                        (coord[1] + draggedCoordinates[index][1]) / 2
                    ]}
                    draggable={true}
                    eventHandlers={markerEventHandlers(index + 1)}
                    icon={icons["eachCoordinate"]}
                    isMidMarker={true}
                />
            ))}
        </>
    );
}
export default UpdatingObject;
