import React from "react";

const HelpAdvisor = () => {
    return (
        <>
            <h3>Hjelp og veiledning</h3>
            <div className="map--help flex--column align-items--center">
                <div className="map--help-text1 flex--row align-items--center">
                    <div className="map--help-circle blue"></div>
                    <p>Viser borettslagets plassering som en sirkel eller et polygon. Klikk på
                        for å se mer informasjon.</p>
                </div>

                <p>Høyreklikk på et borettslag for å vise radius for oppkoblinger. Fargen viser
                    mulighet for nye oppkoblinger.</p>
                <div className="map--help-icons flex--row justify-content-se">
                    <div className="flex--column justify-content-center align-items--center">
                        <div className="map--help-circle green"></div>
                        <p>Høy</p>
                    </div>

                    <div className="flex--column justify-content-center  align-items--center">
                        <div className="map--help-circle orange"></div>
                        <p>Middels</p>
                    </div>

                    <div className="flex--column justify-content-center  align-items--center">
                        <div className="map--help-circle red"></div>
                        <p>Lav</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default HelpAdvisor