import {useRef, useState} from "react";

const Help = () => {
    const [closed, setClosed] = useState(true);
    const [helpClosed, setHelpClosed] = useState({
        mapIcons: true,
        filter: true,
        search: true,
        drawing: true
    });
    const bottomRef = useRef(null);

    const toggleCloseClass = (helpName) => {
        setHelpClosed((prevStatus) => ({
            ...prevStatus,
            [helpName]: !prevStatus[helpName]
        }));
    };

    return (
        <div className={`map--side-table ${closed ? 'close' : ''}`}>
            <div className={`map--side-table-header flex--row align-items--center justify-content-sb`}
                 onClick={() => setClosed(!closed)}
            >
                <h3>Hjelp</h3>
                <svg className="icon--white-chevron"/>
            </div>
            <div className={`map--side-table-container`}>
                <div className={`map--side-table-closeable help ${helpClosed['mapIcons'] ? 'close' : ''}`}>
                    <h4 className="flex--row align-items--center justify-content-sb"
                        onClick={() => toggleCloseClass('mapIcons')}>
                        Kart og Ikoner
                        <svg className="icon--blue-chevron"/>
                    </h4>
                    <div className="help--content flex--column">
                        <p>
                            Alle ikoner, linjer og polygoner på kartet er klikkbare. Når du klikker på et objekt, vises
                            en popup med informasjon om det.
                        </p>
                        <br/>
                        <p>De ulike ikonene på kartet er:</p>
                        <div className="flex--row align-items--center">
                            <svg className="icon--kum"/>
                            <p>Kummer</p>
                        </div>
                        <div className="flex--row align-items--center">
                            <svg className="icon--kum-wall"/>
                            <p>Veggskap og skjøtebokser</p>
                        </div>
                        <div className="flex--row align-items--center">
                            <svg className="icon--node"/>
                            <p>Noder</p>
                        </div>
                        <div className="flex--row align-items--center">
                            <svg className="icon--node-dist"/>
                            <p>Dister</p>
                        </div>
                        <div className="flex--row align-items--center">
                            <svg className="icon--cable"/>
                            <p>Kabler</p>
                        </div>
                        <div className="flex--row align-items--center">
                            <svg className="icon--pipe"/>
                            <p>Rør</p>
                        </div>
                        <div className="flex--row align-items--center">
                            <svg className="icon--homeownerassoc"/>
                            <p>Borettslag</p>
                        </div>
                        <div className="flex--row align-items--center">
                            <svg className="icon--homeownerassoc-netadmin"/>
                            <p>Borettslag hentet fra Netadmin. Begrenset hvilke dataer som kan endres</p>
                        </div>
                    </div>
                </div>
                <div className={`map--side-table-closeable help ${helpClosed['filter'] ? 'close' : ''}`}>
                    <h4 className="flex--row align-items--center justify-content-sb"
                        onClick={() => toggleCloseClass('filter')}>
                        Filter
                        <svg className="icon--blue-chevron"/>
                    </h4>
                    <div className="help--content flex--column">
                        <p>Ved å fjerne avmerkingen i filtreringsmenyen skjules alle objekter på kartet som matcher. For
                            eksempel, hvis du fjerner avmerkingen for "Dist", vil alle noder av typen "Dist" forsvinne
                            fra kartet.</p>
                    </div>
                </div>
                <div className={`map--side-table-closeable help ${helpClosed['search'] ? 'close' : ''}`}>
                    <h4 className="flex--row align-items--center justify-content-sb"
                        onClick={() => toggleCloseClass('search')}>
                        Søking
                        <svg className="icon--blue-chevron"/>
                    </h4>
                    <div className="help--content flex--column">
                        <p>Du kan søke etter enten objekter på kartet eller en adresse. Klikker du på et resultat, vil
                            kartet sentreres på den valgte adressen eller objektet.</p>
                        <br/>
                        <p>For å søke etter adresser i stedet for objekter, bruk bryteren ved siden av "Søk etter
                            objekt". Klikk igjen for å bytte tilbake til objektsøk.</p>
                        <br/>
                        <p>Ved objektsøk vises alle treff basert på enten navn eller adresse i resultatlisten. Hvis du
                            holder musepekeren over et resultat, vil kartet bevege seg til objektet og vise en
                            informasjonsboks. Ved adressearch vises alle treff på adresser i en egen resultatliste.</p>
                    </div>
                </div>
                <div className={`map--side-table-closeable help ${helpClosed['drawing'] ? 'close' : ''}`}>
                    <h4 className="flex--row align-items--center justify-content-sb"
                        onClick={() => toggleCloseClass('drawing')}>
                        Tegning på kart
                        <svg className="icon--blue-chevron"/>
                    </h4>
                    <div className="help--content flex--column">
                        <p>
                            Du kan tegne tre typer objekter på kartet: Punkter (noder og tilkoblingspunkter), Linjer
                            (rør og kabler) og Borettslag (polygon).
                        </p>
                        <br/>
                        <p>
                            Når du er ferdig med å tegne en linje eller et polygon, kan du høyreklikke på kartet for å
                            avslutte tegnemodus uten å slette. Hvis du trykker på "Slett Linje" eller "Slett Areal",
                            slettes tegningen, og du må starte på nytt.
                        </p>
                        <br/>
                        <p>
                            For å fjerne uønskede koordinater fra en linje eller et polygon, bruk "Flytt"-knappen i
                            objektets popup. Når du er i redigeringsmodus, hold inne shift og klikk på koordinatene du
                            vil fjerne. Foreløpig er dette kun mulig via "Flytt", ikke under opprettelse.
                        </p>

                    </div>
                </div>
                <div ref={bottomRef}></div>
            </div>
        </div>
    );
}
export default Help;