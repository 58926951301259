import React, {useEffect, useState} from "react";
import apiUrls from "../../../../configs/api";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import HandleDataContainer from "../../../containers/HandleDataContainer";
import Post from "../../../../functions/apiCalls/Post/Post";
import Delete from "../../../../functions/apiCalls/Delete";

const HandleDataDisplay = () => {
    const [formData, setFormData] = useState({
        type: "connectionPointTypes",
        name: ""
    });
    const [data, setData] = useState({
        connectionPointTypes: null,
        spliceEnclosureTypes: null,
        pipeColors: null,
        pipeSizes: null,
        cableTypes: null,
    });
    const [isOpen, setIsOpen] = useState({
        connectionPointTypes: false,
        spliceEnclosureTypes: false,
        pipeColors: false,
        pipeSizes: false,
        cableTypes: false,
    });

    const toggleIsOpen = (type) => {
        setIsOpen((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const selectedEndpoints = [
                    'connectionPointTypes',
                    'spliceEnclosureTypes',
                    'pipeColors',
                    'pipeSizes',
                    'cableTypes',
                ];
                const results = await Promise.all(
                    selectedEndpoints.map(async (key) => {
                        const result = await GetAll(apiUrls.get[key]);
                        return { key, result };
                    })
                );
                const updatedData = results.reduce((acc, { key, result }) => {
                    acc[key] = result;
                    return acc;
                }, {});
                setData(updatedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            if (!formData.name.trim()) {
                console.warn("Navn Må sendes med");
                return;
            }
            const endpoint = apiUrls.post[`create${formData.type.charAt(0).toUpperCase() + formData.type.slice(1)}`];
            const newData = { name: formData.name };
            const response = await Post(endpoint, newData);
            if (response.ok) {
                const addedData = await response.json();
                setData((prevData) => ({
                    ...prevData,
                    [formData.type]: prevData[formData.type]
                        ? [...prevData[formData.type], addedData]
                        : [addedData],
                }));
                setFormData((prevData) => ({
                    ...prevData,
                    name: "",
                    type: formData.type
                }));
            } else {
                console.error('Kunne ikke opprette', response.status);
            }
        } catch (error) {
            console.error("Kunne ikke opprette", error);
        }
    };

    const handleDelete = async (type, id) => {
        try {
            if (!type || type === "" || !id || id === "") {
                console.warn("Mottok ikke korrekt data");
                return;
            }
            const endpoint = apiUrls.delete[`delete${type.charAt(0).toUpperCase() + type.slice(1)}`];
            const response = await Delete(endpoint, id);
            if (response) {
                setData((prevData) => {
                    const updatedData = prevData[type].filter(item => item.id !== id);
                    return {
                        ...prevData,
                        [type]: updatedData,
                    };
                });
            }
        }
        catch (e) {
            console.error("Kunne ikke slette", e);
        }
    }

    return (
        <div className="profile--display-body profile--admin-handle-data higher flex--row justify-content-sb">
            <div>
                <h3>Legg Til</h3>
                <form onSubmit={handleAdd}>
                    <label className="flex--column">
                        Liste å legge inn i
                    </label>
                    <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                    >
                        <option value="connectionPointTypes">Tilkoblingspunkt Typer</option>
                        <option value="spliceEnclosureTypes">Skjøte Typer</option>
                        <option value="pipeSizes">Rør Størrelser</option>
                        <option value="pipeColors">Rør Farger</option>
                        <option value="cableTypes">Kabel Typer</option>
                    </select>

                    <label className="flex--column">Navn</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />

                    <div className="flex--column justify-content-center align-items--center">
                        <button className="btn btn-secondary-white-bg" type="submit">
                            Legg Til
                        </button>

                        {formData.type === "connectionPointTypes" && (
                            <p className="profile--admin-handle-data-note">
                                Hvis den nye tilkoblingspunkttypen er et veggskap, må navnet starte med "Veggskap" for
                                at riktig ikon skal vises på kartet.
                                Eksempel: "Veggskap (Under bakken)".
                            </p>
                        )}
                    </div>
                </form>
            </div>
            <div className="flex--column">
                <h3>Eksisterende Data</h3>
                <div className="flex--column profile--admin-handle-data-containers">
                    <HandleDataContainer header="Tilkoblingspunkt Typer" datas={data.connectionPointTypes} isOpen={isOpen} type="connectionPointTypes" toggleIsOpen={toggleIsOpen} handleDelete={handleDelete}/>
                    <HandleDataContainer header="Skjøte Typer" datas={data.spliceEnclosureTypes} isOpen={isOpen} type="spliceEnclosureTypes" toggleIsOpen={toggleIsOpen} handleDelete={handleDelete}/>
                    <HandleDataContainer header="Rør Størrelser" datas={data.pipeSizes} isOpen={isOpen} type="pipeSizes" toggleIsOpen={toggleIsOpen} handleDelete={handleDelete}/>
                    <HandleDataContainer header="Rør Farge" datas={data.pipeColors} isOpen={isOpen} type="pipeColors" toggleIsOpen={toggleIsOpen} handleDelete={handleDelete}/>
                    <HandleDataContainer header="Kabel Typer" datas={data.cableTypes} isOpen={isOpen} type="cableTypes" toggleIsOpen={toggleIsOpen} handleDelete={handleDelete}/>
                </div>
            </div>
        </div>
    );
}
export default HandleDataDisplay;