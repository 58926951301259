import React, {useEffect, useState} from 'react';
import apiUrls from "../../../configs/api";
import GetById from "../../../functions/apiCalls/Get/GetById";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import PopUpButtons from "./PopUpButtons";

const PopupCables = ({ item, handleUpdateCoordinates }) => {
    const [pipe, setPipeData] = useState('');
    const [kummer, setKummerData] = useState([]);
    const [node, setNodeData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (item.properties.pipeId !== null) {
                    const data = await GetById(apiUrls.get.pipes, item.properties.pipeId);
                    if (data) {
                        setPipeData(data);
                    }
                }
                if (item.properties.kummer && item.properties.kummer.length > 0) {
                    const cableKummerIds = item.properties.kummer.map(kum => kum.pointId);
                    const filteredKummerPromises = cableKummerIds.map(id => GetById(apiUrls.get.kummer, id));
                    const filteredKummer = await Promise.all(filteredKummerPromises);
                    if (filteredKummer) {
                        setKummerData(filteredKummer);
                    }
                }
                if (item && item.properties && item.properties.nodeId) {
                    const node = await GetById(apiUrls.get.nodes, item.properties.nodeId);
                    setNodeData(node);
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
    }, [item.properties.pipeId]);

    return (
        <div className="flex--column justify-content-center map--popup">
            <div className="flex--row align-items--center map-popup-header">
                <svg className="icon--cable" />
                <h4>{item.properties.name}</h4>
            </div>
            <div className="flex--row justify-content-sb map--popup-body">
                <div className="flex--column map--popup-info">
                    <div className="flex--row long">
                        <p><b>Status:</b></p>
                        <p>{item.properties.status}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>Type:</b></p>
                        <p>{item.properties.cableType}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>I Rør: </b></p>
                        {item.properties.pipeId === null ?
                            <p>Nei</p>
                            :
                            <p>{pipe && pipe.properties.name}</p>
                        }
                    </div>
                    <div className="flex--column tall">
                        <p><b>Tilkoblingspunkter Tilkoblet:</b></p>
                        <div className="flex--row map--popup-list">
                            {kummer.length > 0 ?
                                kummer.map((kum, index) => (
                                    <a href={`/kum/${kum.id}`} key={index}>{kum.name}</a>
                                )) : (
                                    <p>Ingen</p>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex--column tall">
                        <p><b>Node Tilkoblet:</b></p>
                        <div className="flex--row map--popup-list">
                            {node ?
                                (
                                    <a href={`/node/${node.id}`}>{node.name}</a>
                                ) : (
                                <p>Ingen</p>
                                )
                            }
                        </div>
                    </div>
                </div>
                <PopUpButtons
                    item={item}
                    type="line"
                    endpoint="kabel"
                    handleUpdateCoordinates={handleUpdateCoordinates}
                />
            </div>
        </div>
    );
};

export default PopupCables;