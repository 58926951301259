import {useMapEvents} from 'react-leaflet';
import {latLng} from "leaflet/src/geo";

const MapEvents = ({
    mapRef,
    hoveredResult,
    setHoveredResult,
    isAddingPoint,
    isUpdatingPoint,
    pipes,
    cables,
    homeownerAssocs,
    setClickedCoordinate,
    snapMarker,
    setSnapMarker,
    isAddingLine,
    isAddingPoly,
    selectedForm,
    setPipeCoordinates,
    setCableCoordinates,
    setHomeownerAssocsCoordinates,
    kummer,
    noder,
    FindIfFullNode,
    setRadius,
    zoomChange,
    setMapCenter,
    getCircleRadius,
    setSnapMessage,
    isUpdatingPolygon,
    setSnappedNode
}) => {
    const handleMouseDown = (e) => {
        const clickedCoordinate = [e.latlng.lat, e.latlng.lng];
        sessionStorage.setItem('lastClickedCoordinate', JSON.stringify(clickedCoordinate));
        mapRef.current.closePopup();
        if (hoveredResult) setHoveredResult(null);
    };

    useMapEvents({
        click(e) {
            //snapping
            if (isAddingPoint || isUpdatingPoint) {
                let minDistance = Number.MAX_VALUE;
                let closestPoint = null;
                let closestPointObject = null;
                let snapDistanceThreshold = 0.0001;

                if (e.target._zoom <= 16) snapDistanceThreshold = 0.0008;
                else if (e.target._zoom === 18) snapDistanceThreshold = 0.00005;

                [pipes, cables, homeownerAssocs].forEach(data => {
                    data.forEach(line => {
                        line.geometry.coordinates.forEach(coord => {
                            const [pointLng, pointLat] = coord;
                            const { lat: cursorLat, lng: cursorLng } = e.latlng;
                            const distance = Math.sqrt(Math.pow(pointLat - cursorLat, 2) + Math.pow(pointLng - cursorLng, 2));

                            if (distance <= snapDistanceThreshold && distance < minDistance) {
                                minDistance = distance;
                                closestPoint = { latitude: pointLat, longitude: pointLng };
                                closestPointObject = line.properties;
                            }
                        });
                    });
                });

                if (closestPoint) {
                    setClickedCoordinate([closestPoint.latitude, closestPoint.longitude]);
                    setSnapMarker(closestPoint);
                    setSnapMessage(`Snappet til ${closestPointObject.name}(${closestPointObject.type})`);
                } else {
                    const { lat, lng } = e.latlng;
                    setClickedCoordinate([lat, lng]);
                    setSnapMarker(null);
                }
            }
            //Snapping
            if (isAddingLine || isAddingPoly || isUpdatingPolygon) {
                let { lat, lng } = e.latlng;
                if (snapMarker) {
                    lat = snapMarker.coordinates.latitude;
                    lng = snapMarker.coordinates.longitude;
                    const snapMarkerData = snapMarker.data ? snapMarker.data.properties : snapMarker;
                    setSnapMessage(`Snappet til ${snapMarkerData.name}(${snapMarkerData.nodeType ? snapMarker.nodeType : (snapMarkerData.kumType ? snapMarkerData.kumType : snapMarkerData.type)})`);
                    if (snapMarkerData.type === "Node") {
                        setSnappedNode(snapMarkerData);
                    }
                }
                if (isAddingLine) {
                    if (selectedForm === "newPipe") {
                        setPipeCoordinates(prevCoordinates => [...prevCoordinates, [lat, lng]]);
                    }
                    if (selectedForm === "newCable") {
                        setCableCoordinates(prevCoordinates => [...prevCoordinates, [lat, lng]]);
                    }
                    setClickedCoordinate(prevCoordinates => [...prevCoordinates, [lat, lng]]);
                } else {
                    setHomeownerAssocsCoordinates(prevCoordinates => [...prevCoordinates, [[lat, lng]]]);
                    setClickedCoordinate(prevCoordinates => [...prevCoordinates, [lat, lng]]);
                }
            }
            if (hoveredResult) setHoveredResult(null);
        },

        mousemove(e) {
            //Snapping
            if (isAddingLine || isAddingPoly || isUpdatingPolygon) {
                let minDistance = Number.MAX_VALUE;
                let closestMarker = null;
                let snapDistanceThreshold = 0.0001;

                if (e.target._zoom <= 16) snapDistanceThreshold = 0.0005;
                else if (e.target._zoom === 18) snapDistanceThreshold = 0.00003;

                [kummer, noder].forEach(data => {
                    data.forEach(marker => {
                        const { latitude: markerLat, longitude: markerLng } = marker.coordinates;
                        const { lat: cursorLat, lng: cursorLng } = e.latlng;
                        const distance = Math.sqrt(Math.pow(markerLat - cursorLat, 2) + Math.pow(markerLng - cursorLng, 2));

                        if (distance <= snapDistanceThreshold && distance < minDistance && !FindIfFullNode(marker)) {
                            minDistance = distance;
                            closestMarker = marker;
                        }
                    });
                });
                [cables, pipes, homeownerAssocs].forEach(dataArray => {
                    dataArray.forEach(data => {
                        if (data.geometry && data.geometry.coordinates) {
                            const { coordinates, type } = data.geometry;
                            const processCoordinates = (coords) => {
                                coords.forEach(coord => {
                                    if (Array.isArray(coord) && coord.length === 2) {
                                        const [markerLng, markerLat] = coord;
                                        const { lat: cursorLat, lng: cursorLng } = e.latlng;
                                        const distance = Math.sqrt(Math.pow(markerLat - cursorLat, 2) + Math.pow(markerLng - cursorLng, 2));

                                        if (distance <= snapDistanceThreshold && distance < minDistance) {
                                            minDistance = distance;
                                            closestMarker = { coordinates: { latitude: markerLat, longitude: markerLng }, data };
                                        }
                                    }
                                });
                            };
                            if (type === "LineString") {
                                processCoordinates(coordinates);
                            } else if (type === "Polygon") {
                                coordinates.forEach(ring => processCoordinates(ring));
                            }
                        } else {
                            console.log(`No valid geometry found for data object.`);
                        }
                    });
                });
                if (minDistance <= snapDistanceThreshold && closestMarker) {
                    const { latitude, longitude } = closestMarker.coordinates;
                    e.latlng = latLng(latitude, longitude);
                    setSnapMarker(closestMarker);
                } else {
                    setSnapMarker(null);
                }
            }
        },
        //Get zoom level
        zoomend(event) {
            const newZoom = event.target._zoom;
            const newRadius = getCircleRadius(newZoom);
            setRadius(newRadius);
            zoomChange(newZoom);
            sessionStorage.setItem('lastZoom', newZoom);
        },
        //Get map center when moving
        moveend(event) {
            const newCenter = event.target.getCenter();
            setMapCenter([newCenter.lat, newCenter.lng]);
        },

        mousedown: handleMouseDown,
    });

    return null;
};

export default MapEvents;
