import {useEffect, useState} from "react";
import GetById from "../../../functions/apiCalls/Get/GetById";
import apiUrls from "../../../configs/api";

const LockedMessage = ({type, lockerId}) => {
    const userId = sessionStorage.getItem("userId");
    const [user, setUser] = useState(null);
    const userIsLocker = userId === lockerId;
    const thisOrThat = type === "tilkoblingspunktet" || type === "borettslaget" || type === "røret" ? "Dette" : "Denne";

    useEffect(() => {
        const fetchUser = async () => {
            const response = await GetById(apiUrls.get.users, lockerId);
            if (response) {
                setUser(response);
            }
        }
        fetchUser();
    }, []);

    return (
        user && type && (
            <div className="info-page--locked flex--row align-items--center">
                <svg className="icon--lock-closed-blue"/>
                <p>{thisOrThat} {type} er låst av {userIsLocker ? 'deg' : user.username }. {userIsLocker ? 'Ordinære brukere kan ikke redigere før du eller en admin åpner for redigering' : 'Kontakt brukeren eller en annen admin konto hvis du trenger å gjøre endringer'}.</p>
            </div>
        )
    );
}
export default LockedMessage;