import GetById from "../apiCalls/Get/GetById";
import HandleNotification from "../notifications/HandleNotification";

const SaveUpdateCoordinates = async (updatingId, updatingEndpoint, isUpdatingLine, isUpdatingPoint, isUpdatingPolygon, clickedCoordinate, updatedCoordinates, setDatabaseUpdateCounter, setIsUpdatingLine, setIsUpdatingPoint, setIsUpdatingPolygon, setUpdatedCoordinates, setUpdatingId, id, update, portData, currentPosition, setCurrentPosition) => {
    try {
        if (!updatingId || !updatingEndpoint) {
            console.error('Invalid id or endpoint');
            return;
        }
        let bodyData;
        if (isUpdatingLine) {
            if (portData) {
                bodyData = {
                    nodeId: portData.nodeId,
                    portConnections: portData.portConnections,
                    updatedCoordinatesList: updatedCoordinates.map(coord => [coord[1], coord[0]]),
                }
                console.log(bodyData);
            }
            else {
                bodyData = {
                    updatedCoordinatesList: updatedCoordinates.map(coord => [coord[1], coord[0]]),
                };
            }
        } else if (isUpdatingPoint) {
            const [latitude, longitude] = currentPosition ?? clickedCoordinate;
            bodyData = {
                coordinates: {
                    latitude,
                    longitude
                },
            };
        } else if (isUpdatingPolygon) {
            bodyData = {
                updatedCoordinatesList: [updatedCoordinates.map(coord => [coord[1], coord[0]])],
            };
        }
        else {
            console.error('Invalid operation: Neither updating point, line or polygon');
            return;
        }
        let response = await update(updatingId, updatingEndpoint, bodyData);
        if (response) {
            setIsUpdatingPoint(false);
            setIsUpdatingLine(false);
            setIsUpdatingPolygon(false)
            setUpdatedCoordinates([]);
            setUpdatingId(null);
            setDatabaseUpdateCounter(prevCounter => prevCounter + 1);
            setCurrentPosition(null);
            
            const trimmedEndpoint = updatingEndpoint.replace('/update-coordinates', '');
            const updatedData = await GetById(trimmedEndpoint, updatingId);
            await HandleNotification(updatedData, 'Endret koordinatene til ', updatedData.properties ? updatedData.properties.name : updatedData.name);
        }
        else {
            console.error('En error oppsto under oppdateringen:');
        }
    } catch (error) {
        console.error('En error oppsto under oppdateringen:', error);
    }
};

export default SaveUpdateCoordinates;