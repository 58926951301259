import React, {useEffect, useState} from 'react';
import apiUrls from "../../../configs/api";
import FormatDate from "../../../functions/formats-and-regex/formatDate";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import GetById from "../../../functions/apiCalls/Get/GetById";

const InfoPageLog = ({ item }) => {
    const createdDate = (item.createdDate || item.properties.createdDate);
    const [users, setUsers] = useState(null);
    const [logs, setLogs] = useState(null);
    const userLoggerId = (item) => {
        return item.properties ? item.properties.createdById : item.createdById;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (item) {
                    const users = await GetAll(apiUrls.get.users);
                    if (users) {
                        setUsers(users);
                    }
                    const logs = await GetById(apiUrls.get.logsForObject, item.id);
                    if (logs && logs.length > 0) {
                        setLogs(logs);
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [item]);

    return (
        <div className='flex--column'>
            <h3>Logger</h3>
            <div className="info-page--log flex--column">
                <div className="flex--row align-items--center info-page--log-headers">
                    <p>Dato</p>
                    <p>Type</p>
                    <p>Melding</p>
                    <p>Bruker</p>
                </div>
                <div className="info-page--log-data flex--column">
                    {logs && logs.length > 0 && (
                        logs
                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                            .map((log, index) => (
                            <div key={index} className="flex--row align-items--center info-page--log-data-row">
                                <p>{FormatDate(log.date)}</p>
                                <p>{log.type}</p>
                                <p>{log.message}</p>
                                <p>{users && (users.find(user => user.id === log.userId)?.username || 'Ukjent')}</p>
                            </div>
                        ))
                    )}
                    <div className="flex--row align-items--center info-page--log-data-row">
                        <p>{FormatDate(createdDate)}</p>
                        <p>Opprettelse</p>
                        <p>Ble opprettet denne datoen.</p>
                        <p>{users && (users.find(user => user.id === userLoggerId(item))?.username || 'Ukjent')}</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default InfoPageLog;
