import formatDate from "../../../functions/formats-and-regex/formatDate";
import React from "react";
import Delete from "../../../functions/apiCalls/Delete";
import apiUrls from "../../../configs/api";

const formatUpdateMessage = (message) => {
    const lines = message.split('\n');
    return lines.map((line, index) => (
        <span key={index}>
            {line}
            <br />
        </span>
    ));
};

const handleDelete = async (id, setUpdates) => {
    const response = await Delete(apiUrls.delete.deleteUpdate, id);
    if (response) {
        setUpdates((prevUpdates) => prevUpdates.filter((update) => update.id !== id));
    }
}

const ProfileUpdate = ({update, user, setUpdates}) => {
    return (
        <div className="profile--dashboard-update flex--column align-items--center br">
            <h3>{update.header}</h3>
            <p><b>{update.type}</b></p>
            <p className="m-t-10px">{formatUpdateMessage(update.message)}</p>
            <p className="date-font m-t-10px">{formatDate(update.date)}</p>
            {user.type === "Utvikler" && (
                <button onClick={() => handleDelete(update.id, setUpdates)} className="btn btn-delete-white-bg">Slett</button>
            )}
        </div>
    );
}
export default ProfileUpdate;