import React, {useEffect, useState} from "react";
import IsDecember from "../../../functions/checks/IsDecember";

const SearchAdvisor = ({onResultHover, netadminData, homeownerAssocData, setMapCenter}) => {
    const [keyword, setKeyword] = useState("");
    const [results, setResults] = useState([]);
    const [placeholder, setPlaceholder] = useState("Etterstad Slottet");
    const [searchData, setSearchData] = useState(null);
    const christmasAreas = ["Nordpolen", "Nissens Verkssted", "Reinsdyrsletten", "Lappland", "Pepperkakebyen", "Blåfjell", "Svingen"];

    console.log(netadminData);

    useEffect(() => {
        if (Array.isArray(netadminData) && netadminData.length > 0 && Array.isArray(homeownerAssocData)) {
            console.log(netadminData);
            const isValidAddress = (address) => {
                const regex = /^[^,]+,\s*\d{4}(?:\s+[A-Za-zåäöÅÄÖ]+)?$/;
                return regex.test(address.trim());
            };

            const filteredNetadminData = netadminData.filter(item => {
                const address = item.properties ? item.properties.address : item.address;
                if (!address || !isValidAddress(address)) {
                    return false;
                }
                return true;
            });

            const filteredHomeownerAssocData = homeownerAssocData.filter(item => {
                const address = item.properties ? item.properties.address : item.address;
                if (!address || !isValidAddress(address)) {
                    return false;
                }
                return true;
            });

            const combinedData = [...filteredNetadminData, ...filteredHomeownerAssocData];
            const uniqueData = Array.from(
                new Map(combinedData.map(item => [item.id, item])).values()
            );
            setSearchData(uniqueData);
        }
    }, [netadminData, homeownerAssocData]);

    useEffect(() => {
        if (IsDecember()) {
            const randomPlaceholder = christmasAreas[Math.floor(Math.random() * christmasAreas.length)];
            setPlaceholder(randomPlaceholder);
        }
    }, []);

    const handleChange = async (event) => {
        const newKeyword = event.target.value;
        setKeyword(newKeyword);
        if (newKeyword.length >= 2) {
            try {
                const searchResults = searchData.filter((item) => {
                    const itemName = item.properties ? item.properties.name : item.name;
                    return itemName.toLowerCase().includes(newKeyword.toLowerCase());
                });
                const uniqueResults = Array.from(
                    new Map(searchResults.map(item => [item.id, item])).values()
                );
                setResults(uniqueResults);
            } catch (error) {
                console.error("Error fetching search results:", error);
            }
        } else {
            setResults([]);
        }
    };

    const handleResultHover = (result) => {
        onResultHover(result);
    };

    return (
        <div className="map--search">
            <div className="input flex--row justify-content-sb align-items--center">
                <input
                    type="text"
                    placeholder={placeholder}
                    value={keyword}
                    onChange={handleChange}
                    className="search-input"
                />
                <svg className="icon--green-search"/>
            </div>
            {netadminData && keyword && keyword.length >= 2 && results && (
                <div className="map--search-results">
                    {results.length > 0 ? (
                        <>
                            <div className="results-header flex--row">
                                <p>Resultater: </p><p>{' '}&nbsp;{results.length}</p>
                            </div>
                            <div className="results-responses">
                                {results.map((result, index) => (
                                    <div key={index}>
                                        <p
                                            className="search--result btn-link"
                                            key={result.id}
                                            onMouseEnter={() => handleResultHover(result)}
                                        >
                                            {result.properties ? result.properties.name : result.name}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className="results-none">Ingen treff ble funnet.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchAdvisor;