import React, { useState } from 'react';
import ViewFeedback from "./developer/ViewFeedback";
import AddUpdate from "./developer/AddUpdate";

const DeveloperDisplay = () => {
    const [activeForm, setActiveForm] = useState('seeFeedback');

    return (
        <div className="profile--display-page with-nav  flex--column">
            <div className="flex--row align-items--center justify-content-se profile--admin-nav-new">
                <button className={`${activeForm === "seeFeedback" ? 'active' : ''}`} type={"button"}
                        onClick={() => setActiveForm("seeFeedback")}>Se Tilbakemeldinger
                </button>
                <button className={`${activeForm === "addUpdate" ? 'active' : ''}`} type={"button"}
                        onClick={() => setActiveForm("addUpdate")}>Post Oppdatering
                </button>
            </div>
            <div>
                {activeForm === 'seeFeedback' && <ViewFeedback/>}
                {activeForm === 'addUpdate' && <AddUpdate/>}
            </div>
        </div>
    );
};

export default DeveloperDisplay;