import React, { useEffect, useState } from 'react';
import InfoMessages from "../../messagesAndPopups/InfoMessages";
import RegexAddress from "../../../functions/formats-and-regex/RegexAddress";
import NewNodeInputs from './Inputs/NodeInputs';
import HandleInputChange from "../../../functions/inputs/HandleInputChange";
import AddMapObjectsIndexButtons from "../../buttons/AddMapObjectsIndexButtons";

const NewNodeForm = ({ onSubmit, isAddingNode, setIsAddingNode, pointCoordinate, setPointCoordinate, currentPosition, setCurrentPosition}) => {
    const initialFormData = {
        name: '',
        address: '',
        floor: '',
        status: 'I bruk',
        latitude: '',
        longitude: '',
        nodeType: 'Dist',
        switchCount: 1,
        portCount: 24,
        notes: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    const stepsCount = 2;
    const [messageData, setMessageData] = useState(null);

    useEffect(() => {
        if (currentPosition) {
            setFormData({ ...formData, latitude: currentPosition[0], longitude: currentPosition[1] });
        }
    }, [currentPosition]);

    useEffect(() => {
        if (isAddingNode && pointCoordinate.length === 2) {
            setFormData(prevData => ({ ...prevData, latitude: pointCoordinate[0], longitude: pointCoordinate[1] }));
        }
        if (!isAddingNode) {
            setFormData(initialFormData);
            setPointCoordinate('');
        }
    }, [pointCoordinate, isAddingNode, setPointCoordinate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { address } = formData;
        if (!RegexAddress(address)) {
            setMessageData({
                type: 'warning',
                message: 'Ugyldig adresseformat. Vennligst skriv inn i formatet "Gateadresse Nummer, Postnummer Sted".'
            });
            return;
        }
        const { name, floor, status, nodeType, latitude, longitude, switchCount, portCount, notes } = formData;
        const lat = parseFloat(latitude);
        const lng = parseFloat(longitude);

        if (name && address && floor && !isNaN(lat) && !isNaN(lng)) {
            const switches = [];
            for (let i = 0; i < switchCount; i++) {
                switches.push({
                    portCount: portCount
                });
            }
            onSubmit({
                name,
                status,
                address,
                floor,
                nodeType,
                switchCount,
                switches,
                notes,
                coordinates: {
                    latitude: lat,
                    longitude: lng,
                }
            });
            setFormData(initialFormData);
        } else {
            setMessageData({
                type: 'warning',
                message: 'Du må fylle inn alle felt!'
            });
        }
    };

    const handleGetCoordinates = () => {
        setIsAddingNode(prev => !prev);
    };

    useEffect(() => {
        if (isAddingNode) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [isAddingNode]);

    return (
        <div className="map--add-form flex--column justify-content-center">
            <div className="map--add-form-header flex--row justify-content-sb">
                <h3>Node</h3>
                <button
                    className={`btn btn-secondary-white-bg flex--row ${isAddingNode ? 'active' : ''}`}
                    onClick={handleGetCoordinates}
                >
                    {isAddingNode ? (
                        <>
                            Slett Punkt <svg className="icon--green-edit" />
                        </>
                    ) : (
                        <>
                            Marker Punkt <svg className="icon--white-edit" />
                        </>
                    )}
                </button>
                <div className="helping-box helping-box--coordinates">
                    {isAddingNode ? (
                        <p>Klikk for å slette markeringene gjort på kartet.</p>
                    ) : (
                        <p>Klikk for å hente koordinatene ved å klikke på kartet.</p>
                    )}
                </div>
            </div>

            <form onSubmit={handleSubmit} className="flex--column justify-content-sb align-items--center">
                <div className="map--add-form-message">
                    {messageData && (
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    )}
                </div>
                <NewNodeInputs handleInputChange={HandleInputChange} currentGroupIndex={currentGroupIndex}
                               formData={formData} setFormData={setFormData}/>

                <AddMapObjectsIndexButtons currentGroupIndex={currentGroupIndex} setCurrentGroupIndex={setCurrentGroupIndex} stepsCount={stepsCount} />
            </form>
        </div>
    );
};

export default NewNodeForm;






