import HeaderLoggedIn from "../../components/headers/headerLoggedIn";
import ProfileMap from "../../components/maps/ProfileMap";
import profilePicture from "../../assets/images/ProfilePic.svg"
import React, {useState, useEffect} from "react";
import GetById from "../../functions/apiCalls/Get/GetById";
import apiUrls from "../../configs/api";
import DeveloperDisplay from "../../components/displays/profile/DeveloperDisplay";
import EditUserDisplay from "../../components/displays/profile/EditUserDisplay";
import AdminDisplay from "../../components/displays/profile/AdminDisplay";
import Dashboard from "../../components/displays/profile/Dashboard";
import FeedbackDisplay from "../../components/displays/profile/FeedbackDisplay";
import ProfileNavButton from "../../components/buttons/ProfileNavButton";

const ProfilePage = () => {
    const userId = sessionStorage.getItem("userId");
    const [user, setUser] = useState(null);
    const [activeForm, setActiveForm] = useState('dashboard');
    const [userChanges, setUserChanges] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await GetById(apiUrls.get.users, userId);
            if (user) {
                setUser(user);
                sessionStorage.setItem("username", user.username);
            }
        };
        fetchUser();
    }, [userChanges]);

    const toggleForm = (form) => setActiveForm(form === activeForm ? 'dashboard' : form);

    const buttonData = [
        { label: 'Mitt Dashboard', form: 'dashboard' },
        { label: 'Endre Bruker', form: 'editUser' },
        ...(user && (user.type === "Admin" || user.type === "Utvikler") ? [{ label: 'Administrer', form: 'adminDisplay' }] : []),
        ...(user && (user.type === "Admin" || user.type === "Ordinær") ? [{ label: 'Tilbakemeldinger', form: 'sendFeedback' }] : []),
        ...(user && user.type === "Utvikler" ? [{ label: 'Utvikling', form: 'developerDisplay' }] : [])
    ];

    return (
        <div className="profile">
            <HeaderLoggedIn/>
            <ProfileMap/>
            {user && (
                <>
                    <div className="flex--column profile--user">
                        <img className="" src={profilePicture} alt="Standard profilbilde"/>
                        <h2 className="flex--column justify-content-center align-items--center">{user.username}</h2>
                    </div>
                    <div className="profile--body flex--row">
                        <div className="profile--nav  flex--column justify-content-center">
                            <div
                                className="profile--nav-buttons flex--column justify-content-center align-items--center">
                                {buttonData.map(({ label, form }) => (
                                    <ProfileNavButton
                                        key={form}
                                        color={'blue'}
                                        label={label}
                                        isActive={activeForm === form}
                                        onClick={() => toggleForm(form)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div
                            className="profile--display flex--column justify-content-center align-items--center">
                            {activeForm === 'dashboard' && <Dashboard activeForm={activeForm} user={user}/>}
                            {activeForm === 'adminDisplay' && <AdminDisplay/>}
                            {activeForm === 'editUser' && <EditUserDisplay user={user} setUserChanges={setUserChanges} />}
                            {activeForm === 'sendFeedback' && <FeedbackDisplay/>}
                            {activeForm === 'developerDisplay' && <DeveloperDisplay/>}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default ProfilePage;