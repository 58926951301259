import React, {useEffect, useState, useRef} from "react";
import "leaflet/dist/leaflet.css";
import {MapContainer, TileLayer, LayerGroup} from "react-leaflet";
import DrawLines from "./forMaps/DrawLines";
import DrawPolys from "./forMaps/DrawPolys";
import PopupKummer from "../messagesAndPopups/mapPopups/PopupKummer";
import PopupNoder from "../messagesAndPopups/mapPopups/PopupNoder";
import PopupHomeownerAssoc from "../messagesAndPopups/mapPopups/PopupHomeownerAssoc";
import PopupPipes from "../messagesAndPopups/mapPopups/PopupPipes";
import PopupCables from "../messagesAndPopups/mapPopups/PopupCables";
import RenderPoints from "./forMaps/renderPoints";
import RenderLines from "./forMaps/renderLines";
import RenderPolygons from "./forMaps/renderPolygons";
import mapMarkers from "../iconsAndStyles/MapMarkers";
import styles from "../iconsAndStyles/Styles";
import update from "../../functions/apiCalls/Update/Update";
import FindIfFullNode from "../../functions/page-spesific/infoPage/FindIfFullNode";
import SaveUpdateCoordinates from "../../functions/map/SaveUpdateCoordinates";
import MapEvents from "../../functions/map/MapEvents";
import RenderSanta from "./forMaps/RenderSanta";
import IsDecember from "../../functions/checks/IsDecember";
import RenderPolygonsNetadmin from "./forMaps/renderPolygonsNetadmin";
import PopupNetadminHomeownerAssoc from "../messagesAndPopups/mapPopups/PopupNetadminHomeownerAssoc";
import CableChooseNodePort from "../messagesAndPopups/mapPopups/CableChooseNodePort";
import CustomAlert from "../messagesAndPopups/CustomAlert";
import MapButtons from "./MapButtons";

const BigMap = ({ mapCenter, setMapCenter, zoom, zoomChange, layerFilters, isAddingPoint, isAddingLine, isAddingPoly, clickedCoordinate, setClickedCoordinate, selectedForm, setSelectedForm, fetchedData, setDatabaseUpdateCounter, hoveredResult, setHoveredResult, isAddressSearch, setIsAddressSearch, userType, setSnappedNode, currentPosition, setCurrentPosition }) => {
    const { kummer, noder, cables, pipes, homeownerAssocs, netadminHomeownerAssocs } = fetchedData;

    const [cableCoordinates, setCableCoordinates] = useState([]);
    const [pipeCoordinates, setPipeCoordinates] = useState([]);
    const [homeownerAssocsCoordinates, setHomeownerAssocsCoordinates] = useState([]);

    const [isUpdatingPoint, setIsUpdatingPoint] = useState(false);
    const [isUpdatingLine, setIsUpdatingLine] = useState(false);
    const [isUpdatingPolygon, setIsUpdatingPolygon] = useState(false);
    const [updatedCoordinates, setUpdatedCoordinates] = useState([]);
    const [updatingId, setUpdatingId] = useState(null);
    const [updatingEndpoint, setUpdatingEndpoint] = useState(null);
    const [saveAble, setSaveAble] = useState(false);
    const [choosePort, setChoosePort] = useState(false);
    const [choosePortCable, setChoosePortCable] = useState(null);
    const [choosePortNode, setChoosePortNode] = useState(null);
    const [portData, setPortData] = useState(null);
    const [snapMessage, setSnapMessage] = useState(null);
    const [snapMarker, setSnapMarker] = useState(null);

    const [radius, setRadius] = useState(getCircleRadius(zoom));
    const mapRef = useRef(null);
    const id = sessionStorage.getItem("userId");
    const lastClickedCoordinate = JSON.parse(sessionStorage.getItem('lastClickedCoordinate'));
    const lastZoom = sessionStorage.getItem('lastZoom');

    function getCircleRadius(zoomLevel) {
        let baseRadius = 50;
        let radius = baseRadius / Math.pow(2, 18 - zoomLevel);
        if (zoomLevel <= 14) {
            radius = 0;
        }
        return radius;
    }

    const handleUpdateCoordinates = (id, endpoint, type) => {
        setUpdatingId(id);
        setUpdatingEndpoint(endpoint);
        setSelectedForm('');

        if (!isUpdatingPoint && type === "point") {
            setIsUpdatingPoint(true);
            setIsUpdatingLine(false);
            setIsUpdatingPolygon(false);
        }
        else if (!isUpdatingLine && type === "line") {
            setIsUpdatingPoint(false);
            setIsUpdatingLine(true);
            setIsUpdatingPolygon(false);

        }
        else if (!isUpdatingPolygon && type === "poly") {
            setIsUpdatingPoint(false);
            setIsUpdatingLine(false);
            setIsUpdatingPolygon(true);
        }
    }

    useEffect(() => {
        const saveUpdatedCoordinates = async () => {
            await SaveUpdateCoordinates(
                updatingId, updatingEndpoint, isUpdatingLine, isUpdatingPoint, isUpdatingPolygon,
                clickedCoordinate, updatedCoordinates, setDatabaseUpdateCounter,
                setIsUpdatingLine, setIsUpdatingPoint, setIsUpdatingPolygon,
                setUpdatedCoordinates, setUpdatingId, id, update, portData, currentPosition, setCurrentPosition
            );
        };
        if (!choosePort && saveAble) {
            saveUpdatedCoordinates()
        }
    }, [saveAble]);

    useEffect(() => {
        if (!isAddingLine && pipeCoordinates.length > 0) {
            setPipeCoordinates([]);
        }
        if ((!isAddingLine && cableCoordinates.length > 0)) {
            setCableCoordinates([]);
        }
        if (!isAddingPoly && homeownerAssocsCoordinates.length > 0) {
            setHomeownerAssocsCoordinates([]);
        }
    }, [isAddingLine, isAddingPoly, pipeCoordinates.length, cableCoordinates.length, homeownerAssocsCoordinates.length]);

    useEffect(() => {
        if (mapRef.current && isAddressSearch) {
            const map = mapRef.current;
            map.setView(mapCenter, zoom);
            setIsAddressSearch(false);
        }
    }, [mapCenter, zoom, isAddressSearch, setIsAddressSearch]);

    useEffect(() => {
        if (mapRef.current && currentPosition){
            const map = mapRef.current;
            map.setView(currentPosition, 18);
        }
    }, [currentPosition]);

    return (
        <div>
            <MapContainer
                className="big-map"
                center={lastClickedCoordinate ?? mapCenter}
                zoom={lastZoom ?? zoom}

                ref={mapRef}
            >
                <TileLayer
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    maxNativeZoom={18}
                    maxZoom={21}
                    minZoom={3}
                />
                <MapEvents
                    mapRef={mapRef}
                    hoveredResult={hoveredResult}
                    setHoveredResult={setHoveredResult}
                    isAddingPoint={isAddingPoint}
                    isUpdatingPoint={isUpdatingPoint}
                    pipes={pipes}
                    cables={cables}
                    homeownerAssocs={homeownerAssocs}
                    setClickedCoordinate={setClickedCoordinate}
                    snapMarker={snapMarker}
                    setSnapMarker={setSnapMarker}
                    isAddingLine={isAddingLine}
                    isAddingPoly={isAddingPoly}
                    selectedForm={selectedForm}
                    setPipeCoordinates={setPipeCoordinates}
                    setCableCoordinates={setCableCoordinates}
                    setHomeownerAssocsCoordinates={setHomeownerAssocsCoordinates}
                    kummer={kummer}
                    noder={noder}
                    FindIfFullNode={FindIfFullNode}
                    setRadius={setRadius}
                    zoomChange={zoomChange}
                    setMapCenter={setMapCenter}
                    getCircleRadius={getCircleRadius}
                    setSnapMessage={setSnapMessage}
                    isUpdatingPolygon={isUpdatingPolygon}
                    setSnappedNode={setSnappedNode}
                />

                {IsDecember() && (
                    <RenderSanta/>
                )}
                {userType === "Rådgiver Read Only" && (
                    <RenderPolygonsNetadmin
                        netadminHomeownerAssocs={netadminHomeownerAssocs}
                        homeownerAssocs={homeownerAssocs}
                        mapIcons={{
                            defaultIcon: mapMarkers["homeownerAssoc"],
                            netadminIcon: mapMarkers["homeownerAssocNetadmin"]
                        }}
                        style={styles["homeownerAssocs"]}
                        radius={radius}
                        PopupComponent={PopupNetadminHomeownerAssoc}
                        hoveredResult={hoveredResult}
                    />
                )}

                {userType !== "Rådgiver Read Only" && (
                    <>
                        {layerFilters.homeownerAssocs.all && (
                            <LayerGroup>
                                <RenderPolygons
                                    data={homeownerAssocs}
                                    mapIcons={{
                                        defaultIcon: mapMarkers["homeownerAssoc"],
                                        netadminIcon: mapMarkers["homeownerAssocNetadmin"]
                                    }}
                                    style={styles["homeownerAssocs"]}
                                    radius={radius}
                                    PopupComponent={PopupHomeownerAssoc}
                                    handleUpdateCoordinates={handleUpdateCoordinates}
                                    isUpdatingPolygon={isUpdatingPolygon}
                                    updatingId={updatingId}
                                    getUpdatedCoordinates={setUpdatedCoordinates}
                                    kummer={kummer}
                                    noder={noder}
                                    snapMarker={snapMarker}
                                    hoveredResult={hoveredResult}
                                    layerFilters={layerFilters}
                                    isAddingPoint={isAddingPoint}
                                    isAddingLine={isAddingLine}
                                    isAddingPoly={isAddingPoly}
                                    setSnapMessage={setSnapMessage}
                                />
                                <DrawPolys
                                    coordinates={homeownerAssocsCoordinates}
                                    color={styles["homeownerAssocs"].color}
                                    isAdding={isAddingPoly}
                                    snapMarker={snapMarker}
                                    isUpdating={false}
                                />
                            </LayerGroup>
                        )}
                        {layerFilters.pipes.all && (
                            <LayerGroup>
                                <RenderLines
                                    data={pipes}
                                    icon={mapMarkers["pipe"]}
                                    style={styles["pipe"]}
                                    PopupComponent={PopupPipes}
                                    handleUpdateCoordinates={handleUpdateCoordinates}
                                    isUpdatingLine={isUpdatingLine}
                                    updatingId={updatingId}
                                    handleUpdatedCoordinates={setUpdatedCoordinates}
                                    kummer={kummer}
                                    noder={noder}
                                    cables={cables}
                                    pipes={pipes}
                                    homeownerAssocs={homeownerAssocs}
                                    hoveredResult={hoveredResult}
                                    layerFilters={layerFilters}
                                    isAddingPoint={isAddingPoint}
                                    isAddingLine={isAddingLine}
                                    isAddingPoly={isAddingPoly}
                                    isUpdatingPoint={isUpdatingPoint}
                                />
                                <DrawLines
                                    coordinates={pipeCoordinates}
                                    color={selectedForm === 'newPipe' ? styles["pipe"].color : undefined}
                                    isAdding={isAddingLine}
                                    snapMarker={snapMarker}
                                />
                            </LayerGroup>
                        )}
                        {layerFilters.noder.all && (
                            <LayerGroup>
                                <RenderPoints
                                    data={noder}
                                    icons={{
                                        defaultIcon: mapMarkers["nodeAccess"],
                                        distIcon: mapMarkers["nodeDist"]
                                    }}
                                    newIcon={mapMarkers[selectedForm]}
                                    movingIcon={{
                                        accessMovingIcon: mapMarkers["moveNodeAccess"],
                                        distMovingIcon: mapMarkers["moveNodeDist"]
                                    }}
                                    isAddingPoint={isAddingPoint}
                                    selectedForm={selectedForm}
                                    clickedCoordinate={clickedCoordinate}
                                    PopupComponent={PopupNoder}
                                    handleUpdateCoordinates={handleUpdateCoordinates}
                                    isUpdatingPoint={isUpdatingPoint}
                                    setIsUpdatingPoint={setIsUpdatingPoint}
                                    setUpdatingId={setUpdatingId}
                                    updatingId={updatingId}
                                    hoveredResult={hoveredResult}
                                    layerFilters={layerFilters}
                                    isAddingLine={isAddingLine}
                                    isUpdatingLine={isUpdatingLine}
                                    isAddingPoly={isAddingPoly}
                                    currentPosition={currentPosition}
                                    setCurrentPosition={setCurrentPosition}
                                />
                            </LayerGroup>
                        )}
                        {layerFilters.kummer.all && (
                            <LayerGroup>
                                <RenderPoints
                                    data={kummer}
                                    icons={{
                                        defaultIcon: mapMarkers["kum"],
                                        wallIcon: mapMarkers["kumWall"]
                                    }}
                                    newIcon={mapMarkers[selectedForm]}
                                    movingIcon={{
                                        kumMovingIcon: mapMarkers["moveKum"],
                                        kumWallMovingIcon: mapMarkers["moveKumWall"]
                                    }}
                                    isAddingPoint={isAddingPoint}
                                    selectedForm={selectedForm}
                                    clickedCoordinate={clickedCoordinate}
                                    PopupComponent={PopupKummer}
                                    handleUpdateCoordinates={handleUpdateCoordinates}
                                    isUpdatingPoint={isUpdatingPoint}
                                    setIsUpdatingPoint={setIsUpdatingPoint}
                                    setUpdatingId={setUpdatingId}
                                    updatingId={updatingId}
                                    hoveredResult={hoveredResult}
                                    layerFilters={layerFilters}
                                    isAddingLine={isAddingLine}
                                    isUpdatingLine={isUpdatingLine}
                                    isAddingPoly={isAddingPoly}
                                    currentPosition={currentPosition}
                                    setCurrentPosition={setCurrentPosition}
                                />
                            </LayerGroup>
                        )}
                        {layerFilters.cables.all && (
                            <LayerGroup>
                                <RenderLines
                                    data={cables}
                                    icon={mapMarkers["cable"]}
                                    style={styles["cable"]}
                                    PopupComponent={PopupCables}
                                    handleUpdateCoordinates={handleUpdateCoordinates}
                                    isUpdatingLine={isUpdatingLine}
                                    updatingId={updatingId}
                                    handleUpdatedCoordinates={setUpdatedCoordinates}
                                    kummer={kummer}
                                    noder={noder}
                                    cables={cables}
                                    pipes={pipes}
                                    homeownerAssocs={homeownerAssocs}
                                    hoveredResult={hoveredResult}
                                    layerFilters={layerFilters}
                                    isAddingPoint={isAddingPoint}
                                    isAddingLine={isAddingLine}
                                    isAddingPoly={isAddingPoly}
                                    isUpdatingPoint={isUpdatingPoint}
                                    setSnapMessage={setSnapMessage}
                                />
                                <DrawLines
                                    coordinates={cableCoordinates}
                                    color={selectedForm === 'newCable' ? styles["cable"].color : undefined}
                                    isAdding={isAddingLine}
                                    snapMarker={snapMarker}
                                />
                            </LayerGroup>
                        )}
                    </>
                )}

            </MapContainer>
            <MapButtons isAddingPoint={isAddingPoint} isAddingLine={isAddingLine} isAddingPoly={isAddingPoint} isUpdatingPoint={isUpdatingPoint} isUpdatingLine={isUpdatingLine} isUpdatingPolygon={isUpdatingPolygon} choosePort={choosePort} updatingId={updatingId} updatedCoordinates={updatedCoordinates} cables={cables} noder={noder} setChoosePort={setChoosePort} setChoosePortCable={setChoosePortCable} setChoosePortNode={setChoosePortNode} setSaveAble={setSaveAble} setIsUpdatingPoint={setIsUpdatingPoint} setIsUpdatingLine={setIsUpdatingLine} setUpdatedCoordinates={setUpdatedCoordinates} setIsUpdatingPolygon={setIsUpdatingPolygon} setUpdatingId={setUpdatingId} setCurrentPosition={setCurrentPosition}/>
            {choosePort && (
                <CableChooseNodePort cable={choosePortCable} node={choosePortNode} setPortData={setPortData} setChoosePort={setChoosePort} setSaveAble={setSaveAble}/>
            )}
            {snapMessage && (
                <CustomAlert message={snapMessage} setMessage={setSnapMessage}/>
            )}
        </div>
    );
};
export default BigMap;