import React, {useEffect, useState} from 'react';
import getAllNodesPorts from "../../../functions/extractors/getAllNodesPorts";
import Update from "../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../configs/api";
import GroupedPorts from "../../../functions/formats-and-regex/GroupedPorts";

const InfoPageConnectionsCable = ({ item, pipe, connectionPoints, node, userType, setUpdatedPorts }) => {
    const [addPorts, setAddPorts] = useState(false);
    const [removePorts, setRemovePorts] = useState(false);

    const [nodesPorts, setNodesPorts] = useState([]);
    const [cablePorts, setCablePorts] = useState([]);

    const [selectedPort, setSelectedPort] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [filteredNodePorts, setFilteredNodePorts] = useState([]);

    useEffect(() => {
        const getPorts = async () => {
            const ports = await getAllNodesPorts(node.id);
            setNodesPorts(ports);
            setFilteredNodePorts(ports);

            const connectedPorts = node.switches.flatMap((switchItem, switchIndex) =>
                switchItem.ports
                    .filter(port => port.connectedEntityId === item.id)
                    .map(port => ({
                        label: `Switch ${switchIndex + 1}, Port ${port.number}`,
                        switchIndex: switchIndex + 1,
                        portNumber: port.number,
                        ...port,
                    }))
            );
            setCablePorts(connectedPorts);
        };
        if (node) {
            getPorts();
        }
    }, [node]);

    const handleStateChange = (setDisplay) => {
        setDisplay(prevDisplay => !prevDisplay);
        setSelectedPort(null);
        setSearchInput("")
    }

    const handlePortSelect = (port) => {
        setSelectedPort(port);
        setSearchInput(port.label);
    };

    const handleSearchInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        setSearchInput(input);

        const filtered = addPorts ? nodesPorts : cablePorts.filter(
            (port) => port.label.toLowerCase().includes(input)
        );
        setFilteredNodePorts(filtered);
    };

    const handleUpdatePort = async () => {
        try {
            const label = selectedPort.label;
            const regex = /Switch (\d+), Port (\d+)/;
            const match = label.match(regex);
            if (match) {
                const switchNum = parseInt(match[1], 10);
                const portNum = parseInt(match[2], 10);
                const data = {
                    cableId: item.id,
                    switchNum,
                    portNum,
                    type: addPorts ? 'add' : 'remove'
                };
                const response = await Update(node.id, apiUrls.put.updateNodePort, data);
                if (response) {
                    setUpdatedPorts(Math.random());
                    setSelectedPort(null);
                    setSearchInput("");
                } else {
                    console.error("Failed to update port:", response);
                }
            } else {
                console.error('Label format is incorrect');
            }
        } catch (e) {
            console.error('Error in handleAddPort:', e);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            setSelectedPort(null);
            setSearchInput("");
            setFilteredNodePorts(nodesPorts);
        }
    };

    return (
        <>
            <div className='flex--column'>
                <h3>Tilkoblinger</h3>
                <div className="flex--row info-page--connections">
                    <div className="flex--column info-page--connections-headers">
                        <p className="flex--row align-items--center">
                            <b>I Rør</b>
                        </p>
                        <p className="flex--row align-items--center">
                            <b>Tilkoblingspunkter</b>
                        </p>
                        <p className="flex--row align-items--center">
                            <b>Node</b>
                        </p>
                    </div>
                    <div className="flex--column info-page--connections-data">
                        <div className="flex--row align-items--center justify-content-sb">
                            <div className="flex--row align-items--center">
                                {pipe ? (
                                        <a href={`/ror/${pipe.id}`}
                                           className="flex--row align-items-center">
                                            {pipe.properties.name}
                                        </a>
                                    )
                                    : (
                                        <p>Denne kabelen er ikke i et rør</p>
                                    )}
                            </div>
                        </div>
                        <div className="flex--row align-items--center justify-content-sb">
                            <div className="flex--row align-items--center">
                                {connectionPoints && connectionPoints.length > 0 ?
                                    connectionPoints.map((connectionPoint, index) => (
                                        <a href={`/kum/${connectionPoint.id}`} key={index}
                                           className="flex--row align-items-center">
                                            {connectionPoint.name}
                                        </a>
                                    ))
                                    : (
                                        <p>Ingen tilkoblingspunkter koblet til denne kabelen</p>
                                    )}
                            </div>
                            <div>
                                <p className="count">({connectionPoints.length})</p>
                            </div>
                        </div>
                        <div className="flex--row align-items--center justify-content-sb">
                            <div className="flex--row align-items--center">
                                {node ? (
                                    <a href={`/node/${node.id}`}
                                       className="flex--row align-items-center">
                                        {node.name}
                                    </a>
                                ) : (
                                    <p>Kabel ikke koblet til node</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex--row align-items--center justify-content-sb">
                    <div className='flex--row info-page--connections cable-nodes'>
                        <div className="flex--column info-page--connections-headers">
                            <p className="flex--row align-items--center">
                                <b>Porter</b>
                            </p>
                        </div>
                        <div className="flex--column info-page--connections-data">
                            <div className="flex--row align-items--center justify-content-sb">
                                <div className="flex--row align-items--center">
                                    {node ? (
                                        Object.entries(GroupedPorts({ portsList: cablePorts })).length > 0 ? (
                                            Object.entries(GroupedPorts({ portsList: cablePorts })).map(([switchNum, ports], index) => (
                                                <p className="port" key={index}>
                                                    Switch {parseInt(switchNum) + 1}, {ports.length > 1 ? `porter ${ports.join(", ")}` : `port ${ports[0]}`}
                                                </p>
                                            ))
                                        ) : (
                                            <p>Ikke tilkoblet noen av nodens porter</p>
                                        )
                                    ) : (
                                        <p>Ikke koblet til en node, derfor ikke koblet til noen porter</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {node && userType !== 'Read Only' && (
                        <div className="flex--row info-page--connections-update-nodes">
                            {(addPorts || removePorts) && (
                                <>
                                    <div className="add-node--searchable-dropdown flex--row align-items--center">
                                        {showDropdown && (
                                            <div className="dropdown-list">
                                                {(addPorts ? filteredNodePorts : cablePorts).map((port, index) => (
                                                    <p
                                                        key={index}
                                                        className={`dropdown-item ${port.taken ? "disabled" : ""}`}
                                                        onMouseDown={() => !port.taken && handlePortSelect(port)}
                                                    >
                                                        {port.label}
                                                    </p>
                                                ))}
                                            </div>
                                        )}
                                        <input
                                            type="text"
                                            value={searchInput}
                                            onChange={handleSearchInputChange}
                                            onFocus={() => {
                                                setShowDropdown(true);
                                                setFilteredNodePorts(addPorts ? nodesPorts : cablePorts);
                                            }}
                                            onBlur={() => setShowDropdown(false)}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Søk etter port..."
                                            className="search-input"
                                        />
                                    </div>
                                    <div className="flex--row align-items--center">
                                        <button
                                            className={`btn btn-${addPorts ? 'secondary' : 'delete'}-white-bg m-l-10px ${selectedPort ? '' : 'disabled'} `}
                                            onClick={() => handleUpdatePort()}
                                            disabled={!selectedPort}
                                        >
                                            {addPorts ? 'Legg Til' : 'Fjern'}
                                        </button>
                                    </div>
                                </>
                            )}
                            <div className="flex--row align-items--center">
                                {!removePorts && (
                                    <button
                                        className={`btn btn-primary-white-bg ${addPorts ? 'active' : ''} m-l-10px `}
                                        onClick={() => handleStateChange(setAddPorts)}
                                    >
                                        {addPorts ? 'Ferdig' : 'Legg Til Porter'}
                                    </button>
                                )}
                                {!addPorts && cablePorts.length > 0 && (
                                    <button
                                        className={`btn ${removePorts ? 'btn-primary-white-bg active' : 'btn-delete-white-bg'} m-l-10px `}
                                        onClick={() => handleStateChange(setRemovePorts)}
                                    >
                                        {removePorts ? 'Ferdig' : 'Fjern Porter'}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default InfoPageConnectionsCable;