import React, { useState, useEffect } from 'react';
import HeaderNotLoggedIn from "../../components/headers/headerNotLoggedIn";
import Login from "../../functions/apiCalls/Post/Login";
import InfoMessages from "../../components/messagesAndPopups/InfoMessages";
import InfoBanner from "../../components/messagesAndPopups/InfoBanner";
import GetAll from "../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../configs/api";
import SmsCodeLogin from "../../components/forms/SmsCodeLogin";
import IsDecember from "../../functions/checks/IsDecember";

const LoginPage = () => {
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(null);
    const [messageData, setMessageData] = useState(null);
    const [criticalMessage, setCriticalMessage] = useState('');
    const [disableLogin, setDisableLogin] = useState(false);
    const [smsCodeMode, setSmsCodeMode] = useState(false);

    useEffect(() => {
        if (token) {
            sessionStorage.setItem('userId', token.id);
            sessionStorage.setItem('username', token.username);
            sessionStorage.setItem('token', token.token);
            window.location.href = '/kart';
        }
    }, [token]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const messages = await GetAll(apiUrls.get.updates);
                if (messages && messages.length > 0) {
                    const sortedMessages = messages.sort((a, b) => {
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);
                        return dateB - dateA;
                    });
                    const latestMessage = sortedMessages[0];
                    if (latestMessage.type === "Kritisk" || latestMessage.type === "Pågående bug" || latestMessage.type === "Planlagt Nedetid") {
                        setCriticalMessage(latestMessage);
                    }
                }
                setDisableLogin(false);
            } catch (error) {
                setCriticalMessage({
                    type: 'error',
                    header: 'Innlogging midlertidig utilgjengelig',
                    message: 'Vi har problemer med å nå serverene våre. Innlogging vil midlertidig være utilgjengelig inntil problemet er løst.',
                });
                setDisableLogin(true);
            }
        }
        fetch();
    }, [])

    const isPhoneNumberOrEmail = (identifier) => {
        const cleanedIdentifier = identifier.trim();
        const cleanedPhone = cleanedIdentifier.replace(/\s+/g, '');

        if (/^\d{8}$/.test(cleanedPhone)) {
            return cleanedPhone.startsWith('+47') ? cleanedPhone : `+47${cleanedPhone}`;
        }
        return identifier;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            if(identifier === '' || password === ''){
                setMessageData({
                    type: 'warning',
                    message: 'Du må fylle inn alle felt.',
                })
                return;
            }
            const checkedIdentifier = isPhoneNumberOrEmail(identifier);
            let bodyData = {
                identifier: checkedIdentifier,
                password: password,
            }
            await Login(bodyData, setToken, setMessageData, setIdentifier, setPassword, setSmsCodeMode);
        } catch (error) {
            setMessageData({
                type: 'error',
                message: 'En feil oppsto. Prøv igjen.',
            })
        }
    };
    return (
        <>
            {IsDecember() ? (
                <InfoBanner item="christmas" />
            ) : criticalMessage && (
                <InfoBanner item={criticalMessage} />
            )}
            <div className="flex--row login">
                <div className="login--background-image"></div> {/* Background image applied to login */}
                <div className="flex--column w-50vw align-items--center justify-content-center login--content">
                    <HeaderNotLoggedIn />
                    <div className="login--message">
                        {messageData && (
                            <InfoMessages type={messageData.type} message={messageData.message} />
                        )}
                    </div>
                    {!smsCodeMode ? (
                        <form name="login" className="flex--column w-22vw h-auto align-items--center login--form">
                            <h2>Logg inn på din Spots bruker!</h2>
                            <label htmlFor="identifier">Brukernavn, epost eller mobilnummer</label>
                            <input type="text" id="identifier" value={identifier}
                                   onChange={(e) => setIdentifier(e.target.value)}
                                   placeholder='Brukernavn'
                                   disabled={disableLogin}
                                   autoComplete="username"
                            />
                            <label htmlFor="password">Passord</label>
                            <input type="password" id="password" value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                                   placeholder='Passord'
                                   disabled={disableLogin}
                                   autoComplete="current-password"
                            />
                            <button disabled={disableLogin} className={`btn btn-primary-white-bg ${disableLogin ? 'disabled' : ''} m-s-auto`} onClick={handleLogin}>Logg inn</button>
                        </form>
                    ) : (
                        <SmsCodeLogin
                            smsMode={smsCodeMode}
                            setSmsMode={setSmsCodeMode}
                            identifier={identifier}
                            setIdentifier={setIdentifier}
                            setPassword={setPassword}
                            setMessageData={setMessageData}
                            setToken={setToken}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default LoginPage;


