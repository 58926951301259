import React, {useEffect, useState} from 'react';
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../../configs/api";
import DeleteUserPopup from "../../../messagesAndPopups/DeleteUserPopup";

const SeeAllUsers = () => {
    const userId = sessionStorage.getItem("userId");
    const [users, setUsersData] = useState([]);
    const [deletedUser, setDeletedUser] = useState(null);
    const [message, setMessage] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await GetAll(apiUrls.get.users);
            if (data) {
                setUsersData(data);
            }
        };
        fetchData();
    }, []);

    const deleteUser = (deletedUser) => {
        if (deletedUser.id === userId) {
            setMessage({
                type:"warning",
                message:"Du kan ikke slette deg selv"
            })
            return;
        }
        setDeletedUser(deletedUser);
        setDeletePopup(true);
    }

    const canDeleteUser = (user) => {
        const loggedInUser = users.find(user => user.id === userId);
        if (user.id === userId) {
            return false;
        }
        else if (user.type === "Utvikler") {
            return false;
        }
        else return !(user.type === "Admin" && loggedInUser.type !== "Utvikler");
    }

    return (
        <>
            <div className="profile--admin-all-users profile--display-body higher">
                {users && users.map(user => (
                    <div key={user.id} className="flex--row justify-content-sb align-items--center">
                        <div className="user br flex--row align-items--center justify-content-sb">
                            <p>{user.username}</p>
                            <p>{user.type}</p>
                        </div>
                        {canDeleteUser(user) && (
                            <button type="button" onClick={() => deleteUser(user)}
                                    className="btn btn-delete-white-bg">Slett
                            </button>
                        )}
                    </div>
                ))}
            </div>
            {deletePopup && (
                <DeleteUserPopup
                    user={deletedUser}
                    setShowDeletePopup={setDeletePopup}
                    setData={setUsersData}
                />
            )}
        </>

    );
};

export default SeeAllUsers;