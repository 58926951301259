import React, {useState, useEffect, useRef} from 'react';
import apiUrls from "../../../../configs/api";
import InfoMessages from "../../../messagesAndPopups/InfoMessages";
import GetUserType from '../../../../functions/apiCalls/Get/GetUserType';
import PhoneRegex from "../../../../functions/formats-and-regex/PhoneRegex";
import {HandleInput, HandleKeyDown} from "../../../../functions/inputs/HandlePhoneNumberInput";
import EmailPhoneChecks from "../../../../functions/inputs/EmailPhoneChecks";
import PostUser from "../../../../functions/apiCalls/Post/PastUser";
import userTypes from "../../../../assets/data/jsonFiles/Users/user-types.json";

const NewUserForm = () => {
    const [formData, setFormData] = useState({
        type: 'Ordinær',
        username: '',
        email: '',
        phoneNumber: '+47'
    });
    const [messageData, setMessageData] = useState();
    const [userType, setUserType] = useState(null);
    const phoneInputRef = useRef(null);
    
    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
        };
        fetchUserType();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!formData.username || !formData.phoneNumber) {
                setMessageData({
                    type: 'warning',
                    message: 'Du må fylle inn alle felt!'
                });
                return;
            }
            if (!formData.email && formData.type !== "Rådgiver Read Only") {
                setMessageData({
                    type: 'warning',
                    message: 'Du må fylle inn alle felt!'
                });
                return;
            }
            const phoneNumber = PhoneRegex(formData.phoneNumber);
            if (formData.type !== "Rådgiver Read Only" && !EmailPhoneChecks(formData, setMessageData, phoneNumber)) {
                return;
            }
            const data = {
                type: formData.type,
                username: formData.username,
                email: formData.email,
                phoneNumber: phoneNumber
            }
            const response = await PostUser(apiUrls.admin.createUser, data);
            if (response.status === 200) {
                setFormData({
                    type: 'Ordinær',
                    username: '',
                    email: '',
                    phoneNumber: '+47'
                })
                setMessageData({
                    type: 'success',
                    message: 'Ny bruker opprettet.'
                });
            }
            else if (response.status === 409) {
                setMessageData({
                    type: 'warning',
                    message: response.message
                });
            } else {
                setMessageData({
                    type: 'error',
                    message: 'En feil oppsto, prøv igjen.'
                });
            }
        } catch (error) {
            setMessageData({
                type: 'error',
                message: 'En feil oppsto, prøv igjen.'
            });
        }
    };

    useEffect(() => {
        const phoneInput = phoneInputRef.current;
        if (phoneInput) {
            phoneInput.addEventListener("keydown", (e) => HandleKeyDown(e, phoneInput));
            phoneInput.addEventListener("input", (e) => HandleInput(e, setFormData));
            return () => {
                phoneInput.removeEventListener("keydown", (e) => HandleKeyDown(e, phoneInput));
                phoneInput.removeEventListener("input", (e) => HandleInput(e, setFormData));
            };
        }
    }, []);

    return (
        <div className="profile--display-body higher">
            {messageData && (
                <div className="profile--display-header-no-h2">
                    <div className="profile--display-header-message">
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    </div>
                </div>
            )}
            <form className="flex--column profile--admin-new-user" onSubmit={handleSubmit}>
                <div className="flex--row justify-content-sb">
                    <div className="flex--column">
                        <label>
                            Bruker Type
                        </label>
                        <select
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                        >
                            {userTypes.userTypes
                                .filter(option => option !== "Utvikler" || userType === "Utvikler")
                                .map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))
                            }
                        </select>
                        {formData.type !== "Rådgiver Read Only" && (
                            <>
                                <label>
                                    Epost Adresse
                                </label>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="epost@obos.no"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                            </>
                        )}

                    </div>

                    <div className="flex--column">
                        <label>
                            Brukernavn
                        </label>
                        <input
                            type="text"
                            name="username"
                            placeholder="DenBesteAnsatte"
                            value={formData.username}
                            onChange={handleChange}
                        />
                        <label>
                            Mobilnummer
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            placeholder="+4799887766"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            ref={phoneInputRef}
                        />
                    </div>
                </div>

                <button className="btn btn-secondary-white-bg m-s-auto" type="submit">
                    Legg Til Ny Bruker
                </button>
            </form>
        </div>
    );
};

export default NewUserForm;