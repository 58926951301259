
// coordinateHandlers.js
export const CancelUpdateCoordinates = (
    isUpdatingPoint, isUpdatingLine, isUpdatingPolygon,
    setIsUpdatingPoint, setIsUpdatingLine, setUpdatedCoordinates, setIsUpdatingPolygon, setUpdatingId
) => {
    if (isUpdatingPoint) {
        setIsUpdatingPoint(false);
    }
    else if (isUpdatingLine) {
        setIsUpdatingLine(false);
        setUpdatedCoordinates([]);
    }
    else if (isUpdatingPolygon) {
        setIsUpdatingPolygon(false);
        setUpdatedCoordinates([]);
    }
    setUpdatingId(null);
};

export const HandleMatchingNodes = (
    updatingId, updatedCoordinates, cables, noder, setChoosePort, setChoosePortCable, setChoosePortNode, setSaveAble
) => {
    const cable = cables.find(c => c.id === updatingId);
    if (!cable) {
        setSaveAble(true);
        return;
    }
    const matchingNodes = noder.filter((node) =>
        updatedCoordinates.some(
            (coord) =>
                coord[0] === node.coordinates.latitude &&
                coord[1] === node.coordinates.longitude
        )
    );
    if (matchingNodes.length > 1) {
        alert("Du kan kun koble kabelen til en node! Flytt kabelen vekk fra en av nodene.");
        return;
    }
    if (matchingNodes.length === 1) {
        for (const matchingNode of matchingNodes) {
            const isCableConnectedToNode = matchingNode.switches.some(switchObj =>
                switchObj.ports.some(port => port.connectedEntityId === updatingId)
            );
            if (!isCableConnectedToNode) {
                setChoosePort(true);
                setChoosePortCable(cable);
                setChoosePortNode(matchingNode);
                return;
            } else {
                setSaveAble(true);
            }
        }
    } else {
        setSaveAble(true);
    }
};

