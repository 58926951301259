import React from 'react';

const CheckboxFilter = ({ name, header, options, filters, handleCheckboxChange, checkboxesClosed, toggleCloseClass }) => {
    return (
        <div
            className={`map--side-table-closeable ${checkboxesClosed[name] ? 'close' : ''}`}>
            <h4 className="flex--row align-items--center justify-content-sb" onClick={() => toggleCloseClass(name)}>
                {header}
                <svg className="icon--white-chevron"/>
            </h4>

            <div className="map--filter--checkbox">
                {options.map((option, index) => (
                    <label className="flex--row align-items--center" key={index}>
                        <input
                            type="checkbox"
                            name={option.name}
                            checked={filters[name][option.name]}
                            onChange={(event) => handleCheckboxChange(event, name, option.saveable)}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default CheckboxFilter;
