import React, { useEffect, useState } from "react";
import getAllNodesPorts from "../../../functions/extractors/getAllNodesPorts";
import CustomSelectForPorts from "../../inputsSelectsCheckboxes/custom/CustomSelectForPorts";
import GroupedPorts from "../../../functions/formats-and-regex/GroupedPorts";

const CableChooseNodePort = ({ cable, node, setPortData, setChoosePort, setSaveAble }) => {
    const [nodePortData, setNodePortData] = useState(null);
    const [chosenPorts, setChosenPorts] = useState([]);

    const [selectedPort, setSelectedPort] = useState(null);
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        if (node) {
            const fetchPorts = async () => {
                const portsData = await getAllNodesPorts(node.id);
                setNodePortData(portsData);
            };
            fetchPorts();
        }
    }, [node]);

    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            const formattedPorts = chosenPorts.map((port) => {
                const parts = port.label.split(", ");
                const switchNum = parts[0].split(" ")[1];
                const portNum = parts[1].split(" ")[1];
                return { switchNum, portNum };
            });

            setPortData({
                nodeId: node.id,
                portConnections: formattedPorts
            });
            setChoosePort(false);
            setSaveAble(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleAddPort = () => {
        if (selectedPort && !chosenPorts.some((port) => port.label === selectedPort.label)) {
            setChosenPorts([...chosenPorts, selectedPort]);
        }
    };

    const handleRemovePort = (port) => {
        setChosenPorts(chosenPorts.filter((chosenPort) => chosenPort.label !== port.label));
    };

    const handleCancel = () => {
        setChosenPorts(null);
        setSelectedPort(null);
        setSearchInput("");
        setChoosePort(false);
    }

    return (
        <div className="choose-port--background popup-opacity-background flex--column align-items--center justify-content-center">
            <div className="choose-port--message flex--column align-items--center justify-content-center">
                <h3>Velg Port</h3>
                <p>
                    Du koblet <strong>{cable.properties.name}</strong> kabelen til {node.nodeType.toLowerCase()}n <strong>{node.name}</strong>. Velg hvilken hvilke porter kabelen skal kobles til. <br/>
                    Om du ikke ønsker å koble kabelen til noen porter klikker du bekreft uten å velge noen porter.
                </p>
                <form onSubmit={handleSubmit} className="flex--column align-items--center justify-content-center">
                    <p><strong>Valgte porter</strong></p>

                    <div className="choose-port--chosen-ports flex--row">
                        {Object.entries(GroupedPorts({ portsList: chosenPorts })).length > 0 ? (
                            Object.entries(GroupedPorts({ portsList: chosenPorts })).map(([switchNum, ports], index) => (
                                <p className="port" key={index} onClick={() => handleRemovePort({ switch: switchNum })}>
                                    Switch {parseInt(switchNum) + 1}, {ports.length > 1 ? `porter ${ports.join(", ")}` : `port ${ports[0]}`}
                                </p>
                            ))
                        ) : (
                            <p>Ingen valgte porter</p>
                        )}
                    </div>

                    <div className="choose-port--select flex--row justify-content-sb align-items--center">
                        <CustomSelectForPorts
                            searchInput={searchInput}
                            setSearchInput={setSearchInput}
                            list={nodePortData}
                            setList={setNodePortData}
                            setSelectedInput={setSelectedPort}
                        />
                        <button
                            onClick={handleAddPort}
                            type="button"
                            className="btn btn-secondary-white-bg"
                        >
                            Legg Til
                        </button>
                    </div>
                    <div className="choose-port--buttons flex--row align-items--center justify-content-sb">
                        <button type="submit" className="btn btn-primary-white-bg">
                            Bekreft
                        </button>
                        <button type="button" className="btn btn-delete-white-bg" onClick={handleCancel}>
                            Avbryt
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CableChooseNodePort;
