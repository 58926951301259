import React, { useEffect, useState } from 'react';
import apiUrls from "../../../configs/api";
import GetById from "../../../functions/apiCalls/Get/GetById";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import ProfileUpdate from "../../containers/profile/ProfileUpdate";
import ProfileNotification from "../../containers/profile/ProfileNotification";

const Dashboard = ({user}) => {
    const [triggerUsers, setTriggerUsers] = useState({});
    const [updates, setUpdates] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationsYou, setNotificationsYou] = useState([]);
    const [notificationsNotYou, setNotificationsNotYou] = useState([]);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [bigScreen, setBigScreen] = useState(window.innerWidth > 2000);

    useEffect(() => {
        const handleResize = () => {
            setBigScreen(window.innerWidth > 2000);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (user && user.notifications) {
            setNotifications(user.notifications);
            const notificationsYou = user.notifications.filter(notification => notification.triggerUserId === user.id);
            const notificationsNotYou = user.notifications.filter(notification => notification.triggerUserId !== user.id);
            setNotificationsYou(notificationsYou);
            setNotificationsNotYou(notificationsNotYou);
        }
    }, [user]);

    useEffect(() => {
        const fetchLatestUpdate = async () => {
            try {
                const allUpdates = await GetAll(apiUrls.get.updates);
                if (allUpdates && allUpdates.length > 0) {
                    setUpdates(allUpdates);
                }
            } catch (error) {
                console.error('Error fetching latest update:', error);
            }
        };

        fetchLatestUpdate();
    }, []);

    useEffect(() => {
        const fetchTriggerUsers = async () => {
            try {
                if (user && user.notifications) {
                    const triggerUserIds = user.notifications.map(notification => notification.triggerUserId);
                    if (triggerUserIds && triggerUserIds.length > 0) {
                        const uniqueTriggerUserIds = Array.from(new Set(triggerUserIds));
                        const triggerUsersData = await Promise.all(
                            uniqueTriggerUserIds.map(async (id) => {
                                try {
                                    return await GetById(apiUrls.get.users, id);
                                } catch (error) {
                                    if (error.response && error.response.status === 404) {
                                        return { id, username: "Ukjent Bruker" };
                                    } else {
                                        console.error('Error fetching user:', error);
                                        return null;
                                    }
                                }
                            })
                        );
                        const triggerUsersObject = {};
                        triggerUsersData.forEach((user, index) => {
                            triggerUsersObject[uniqueTriggerUserIds[index]] = user || { id: uniqueTriggerUserIds[index], username: "Ukjent Bruker" };
                        });
                        setTriggerUsers(triggerUsersObject);
                    }
                }
            } catch (error) {
                console.error('Error fetching trigger users data:', error);
            }
        };
        fetchTriggerUsers();
    }, [user]);

    return (
        <div className="profile--display-page-dashboard flex--row justify-content-sb align-items--center">
            {updates && notifications && user && (
                <>
                    <div className={`profile--dashboard--updates ${notificationsOpen ? 'slim' : 'wide-input'} flex--column align-text--center`}>
                        {updates.length > 0 ? (
                            updates.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 10).map((update, index) => (
                                <ProfileUpdate
                                    key={index}
                                    update={update}
                                    user={user}
                                    setUpdates={setUpdates}
                                />
                            ))
                        ) : (
                            <div className="profile--dashboard-update">
                                <h3>Ingen nye oppdateringer</h3>
                            </div>
                        )}
                    </div>

                    {user && user.type !== "Rådgiver Read Only" && (
                        bigScreen ? (
                            <div className={`profile--dashboard--notifications-big-screen flex--row`}>
                                <div className="dashboard--notifications-container">
                                    <div className="profile--display-header-notifications flex--row align-items--center">
                                        <h2>Varsler</h2>
                                    </div>
                                    <div className="flex--row justify-content-sb dashboard--notifications-columns">
                                        <div className="profile--dashboard--all-notifications flex--column justify-content-center align-items--center">
                                            {user && notifications && notifications.length > 0 ? (
                                                notificationsNotYou.slice(-10).reverse().map((notification, index) => (
                                                    <ProfileNotification
                                                        key={index}
                                                        triggerUsers={triggerUsers}
                                                        notification={notification}
                                                        user={user}
                                                        userIsTriggerUser={false}
                                                    />
                                                ))
                                            ) : (
                                                <p>Du har ingen varsler for øyeblikket.</p>
                                            )}
                                        </div>
                                        <div className="profile--dashboard--all-notifications">
                                            {user && notifications && notifications.length > 0 ? (
                                                notificationsYou.slice(-10).reverse().map((notification, index) => (
                                                    <ProfileNotification
                                                        key={index}
                                                        triggerUsers={triggerUsers}
                                                        notification={notification}
                                                        user={user}
                                                        userIsTriggerUser={true}
                                                    />
                                                ))
                                            ) : (
                                                <p>Du har ingen varsler for øyeblikket.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={`profile--dashboard--notifications ${notificationsOpen ? 'open' : ''} flex--row`}>
                                <svg className="icon--blue-chevron" onClick={() => setNotificationsOpen(!notificationsOpen)}/>
                                <div className="dashboard--notifications-container">
                                    <div className="profile--display-header-notifications flex--row align-items--center">
                                        <h2>Varsler</h2>
                                    </div>
                                    <div className="profile--dashboard--all-notifications">
                                        {user && notifications && notifications.length > 0 ? (
                                            notifications.slice(-10).reverse().map((notification, index) => (
                                                <ProfileNotification
                                                    key={index}
                                                    triggerUsers={triggerUsers}
                                                    notification={notification}
                                                    user={user}
                                                    userIsTriggerUser={triggerUsers[notification.triggerUserId]?.username === user.username}
                                                />
                                            ))
                                        ) : (
                                            <p>Du har ingen varsler for øyeblikket.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </>
            )}
        </div>
    );
};

export default Dashboard;



