import apiUrls from "../../configs/api";

const DetermineRedirectUrl = (item) => {
    if (item && (item.properties?.type || item.type)) {
        switch (item.properties?.type || item.type) {
            case 'Kum': return "kum";
            case 'Node': return "node";
            case 'Kabel': return "kabel";
            case 'Rør': return "ror";
            case 'Borettslag': return "borettslag";
            default: return '';
        }
    }
    return '';
};

const DetermineApiUrls = (item) => {
    const itemType =  item.properties ? item.properties.type : item.type;
    let returnUrl = "";
    if (itemType) {
        switch (itemType) {
            case 'Kum':
                returnUrl = apiUrls.delete.deleteKum;
                break;
            case 'Node':
                returnUrl = apiUrls.delete.deleteNode;
                break;
            case 'Kabel':
                returnUrl = apiUrls.delete.deleteCable;
                break;
            case 'Rør':
                returnUrl = apiUrls.delete.deletePipe;
                break;
            case 'Borettslag':
                returnUrl = apiUrls.delete.deleteHomeownerAssoc;
                break;
            default:
                console.log("Item type not matched, returning empty URL");
                break;
        }
    } else {
        console.log("No valid item type found");
    }
    return returnUrl;
};

export {DetermineApiUrls, DetermineRedirectUrl};