import React from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON } from 'react-leaflet';
import { divIcon } from "leaflet/src/layer";
import L from "leaflet";

const MiniMap = ({ marker, icon, style, zoom, coordinates }) => {
    const FindObjectCenter = (object) => {
        if (!object) return null;
        if (object.type === "Kum" || object.type === "Node") {
            return L.latLng(object.coordinates.latitude, object.coordinates.longitude);
        }
        if (object.geometry.type === "Polygon") {
            const coordinates = L.polygon(object.geometry.coordinates[0].map(coord => [coord[1], coord[0]]));
            return coordinates.getBounds().getCenter();
        }
        if (object.geometry.type === "LineString") {
            const lineCoords = object.geometry.coordinates.map(coord => [coord[1], coord[0]]);
            const middleIndex = Math.floor(lineCoords.length / 2);
            return L.latLng(lineCoords[middleIndex][0], lineCoords[middleIndex][1]);
        }
        return null;
    };
    const center = marker ? FindObjectCenter(marker) : coordinates;

    const getMarker = (type) => {
        if (type) {
            if (type === "kum" || type === "kum-wall") {
                return divIcon({
                    className: `icon--${type}`,
                    iconSize: [24, 24],
                    iconAnchor: [6, 12],
                });
            }
            else {
                return divIcon({
                    className: `icon--${type}-marker`,
                    iconSize: [36, 36],
                    iconAnchor: [14, 34],
                });
            }
        }
        return null;
    };

    return (
        center && (
            <MapContainer
                center={center}
                zoom={zoom}
                className="h-100vh w-25vw"
                scrollWheelZoom={false}
                doubleClickZoom={false}
                dragging={false}
                zoomControl={false}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {marker && marker.geometry && (marker.geometry.type === "Polygon" || marker.geometry.type === "LineString") && (
                    <GeoJSON
                        data={marker}
                        style={style}
                    />
                )}
                {marker && (
                    <Marker position={center} icon={getMarker(icon)} />
                )}
            </MapContainer>
        )
    );
};

export default MiniMap;
