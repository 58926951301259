import React, {useEffect, useRef, useState} from 'react';
import InfoMessages from "../../../messagesAndPopups/InfoMessages";
import apiUrls from "../../../../configs/api";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import GetById from "../../../../functions/apiCalls/Get/GetById";
import userTypes from "../../../../assets/data/jsonFiles/Users/user-types.json";
import GetUserType from '../../../../functions/apiCalls/Get/GetUserType';
import {HandleInput, HandleKeyDown} from "../../../../functions/inputs/HandlePhoneNumberInput";
import PhoneRegex from "../../../../functions/formats-and-regex/PhoneRegex";
import EmailPhoneChecks from "../../../../functions/inputs/EmailPhoneChecks";
import UpdateUser from "../../../../functions/apiCalls/Update/UpdateUser";

const EditUsersForm = () => {
    const [users, setUsersData] = useState([]);
    const [chosenUser, setChosenUser] = useState('');
    const [formData, setFormData] = useState({
        userId: 'Velg',
        username: '',
        email: '',
        phoneNumber: '+47',
        password: false,
        type: chosenUser.type
    });
    const [messageData, setMessageData] = useState(null);
    const [userType, setUserType] = useState(null);
    const phoneInputRef = useRef(null);

    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
        };
        fetchUserType();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,  // Toggle checkbox based on `checked`
            ...(name === 'userId' && { userId: value }),  // Special case for userId
            ...(name === 'userType' && { type: value })    // Special case for userType
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await GetAll(apiUrls.get.users);
            if (data) {
                const filteredUsers = data.filter(user => user.type !== "Rådgiver Read Only");
                setUsersData(filteredUsers);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (formData.userId !== 'Velg'){
                const chosenUser = await GetById(apiUrls.get.users, formData.userId);
                if (chosenUser) {
                    setChosenUser(chosenUser);
                    setFormData(prevState => ({
                        ...prevState,
                        type: chosenUser.type,
                        username: chosenUser.username,
                        email: chosenUser.email ? chosenUser.email : '',
                        phoneNumber: chosenUser.phoneNumber ? chosenUser.phoneNumber : '+47',
                    }));
                }
            }
            else {
                setChosenUser('');
            }
        };
        fetchData();
    }, [formData.userId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formData.userId == null || formData.userId === "Velg"){
                setMessageData({
                    type: 'warning',
                    message: 'Du må fylle inn alle felt!'
                });
                return;
            }
            const phoneNumber = PhoneRegex(formData.phoneNumber);
            if (!EmailPhoneChecks(formData, setMessageData, phoneNumber)) {
                return;
            }
            const data = {
                username: formData.username,
                email: formData.email,
                phoneNumber: phoneNumber,
                newPassword: formData.password,
                type: formData.type
            }
            const response = await UpdateUser(formData.userId, apiUrls.admin.changeUser, data);
            if (response.status === 200) {
                setFormData({
                    userId: 'Velg',
                    email: '',
                    username: '',
                    phoneNumber: '+47',
                    password: false,
                    type: chosenUser?.type || ''
                });
                setMessageData({
                    type: 'success',
                    message: 'Bruker oppdatert.'
                });
            }
            else if (response.status === 409) {
                setMessageData({
                    type: 'warning',
                    message: response.message
                });
            } else {
                if (response.status === 401) {
                    setMessageData({
                        type: 'warning',
                        message: 'Du kan ikke endre brukerens informasjon.'
                    });
                } else {
                    setMessageData({
                        type: 'error',
                        message: 'En feil oppsto, prøv igjen.'
                    });
                }
            }
        } catch (error) {
            setMessageData({
                type: 'error',
                message: 'En feil oppsto, prøv igjen.'
            })
        }
    };

    useEffect(() => {
        const phoneInput = phoneInputRef.current;
        if (phoneInput) {
            phoneInput.addEventListener("keydown", (e) => HandleKeyDown(e, phoneInput));
            phoneInput.addEventListener("input", (e) => HandleInput(e, setFormData));
            return () => {
                phoneInput.removeEventListener("keydown", (e) => HandleKeyDown(e, phoneInput));
                phoneInput.removeEventListener("input", (e) => HandleInput(e, setFormData));
            };
        }
    }, []);

    return (
        <div className="profile--display-body higher">
            {messageData && (
                <div className="profile--display-header-no-h2">
                    <div className="profile--display-header-message">
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    </div>
                </div>
            )}
            <form className="flex--column profile--admin-edit-user" onSubmit={handleSubmit}>
                <div className="flex--row justify-content-sb">
                    <div className="flex--column">
                        <label className="flex--column">
                            Brukere
                        </label>
                        <select
                            name="userId"
                            value={formData.userId}
                            onChange={handleChange}
                        >
                            <option value="Velg">Velg Bruker</option>
                            {users && users
                                .filter(user => {
                                    if (userType === 'Utvikler') {
                                        return user.type !== 'Utvikler';
                                    } else {
                                        return user.type !== 'Utvikler' && user.type !== 'Admin';
                                    }
                                })
                                .map(user => (
                                    <option key={user.id} value={user.id}>{user.username}</option>
                                ))
                            }
                        </select>

                        <label className="flex--column">
                            Brukernavn
                        </label>
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                        />

                        <label className="flex--column">
                            Mobilnummer
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder="+4799887766"
                            ref={phoneInputRef}
                        />
                    </div>
                    <div className="flex--column">
                        <label>
                            Type
                        </label>
                        <select
                            name="userType"
                            value={formData.type}
                            onChange={handleChange}
                        >
                            {userTypes.userTypes
                                .filter(option => option !== "Utvikler")
                                .map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))
                            }
                        </select>
                        <label className="flex--column">
                            Epost Adresse
                        </label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="epost@obos.no"
                        />
                        <label className="flex--column">
                            Generer nytt passord?
                        </label>
                        <div className="flex--row align-items--center">
                            <input
                                type="checkbox"
                                name="password"
                                checked={formData.password}
                                onChange={handleChange}
                            />
                            <p>{formData.password ? 'Ja' : 'Nei'}</p>
                        </div>
                    </div>
                </div>
                <button className="btn btn-secondary-white-bg m-s-auto" type="submit">
                    Lagre Endringer
                </button>
            </form>
        </div>
    );
};

export default EditUsersForm;
