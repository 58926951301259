const HoverPopupClick = (hoveredResult, markerRef) => {
    if (hoveredResult && markerRef.current) {
        const hoveredId = hoveredResult.id;
        if (hoveredId && markerRef.current) {
            const marker = markerRef.current;
            marker.fire('click');
            setTimeout(() => {
                marker.fire('click');
            }, 500);
        }
    }
}
export default HoverPopupClick;