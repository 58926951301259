import React, {useEffect, useRef, useState} from "react";
import {LayerGroup, GeoJSON, Popup, Marker} from "react-leaflet";
import findMarkerCoordinate from "../../../functions/map/FindMarkerCoordinate";
import icons from "../../iconsAndStyles/Icons";
import AllRenderFilter from "../../../functions/filter/filterRenders/AllRenderFilter";
import LineRenderFilter from "../../../functions/filter/filterRenders/LineRenderFilter";
import FindIfFullNode from "../../../functions/page-spesific/infoPage/FindIfFullNode";
import ProcessCoordinates from "../../../functions/map/ProcessCoordinates";
import UpdatingObject from "./UpdatingObject";
import HoverPopupClick from "../../../functions/map/HoverPopupClick";

const RenderLines = ({ handleUpdatedCoordinates, icon, data, style, PopupComponent, handleUpdateCoordinates, isUpdatingLine, updatingId, kummer, noder, cables, pipes, homeownerAssocs, hoveredResult, layerFilters, isAddingPoint, isAddingLine, isAddingPoly, isUpdatingPoint, setSnapMessage }) => {
    const isUpdating = (item) => {
        return isUpdatingLine && updatingId === item.id
    }
    const showIcon = (item) => {
        return layerFilters.all.icons && (item.properties.type === "Rør" ? layerFilters.pipes.icons : layerFilters.cables.icons);
    }
    const isAdding = isAddingLine || isAddingPoint || isAddingPoly;
    const movingPoint = isAddingPoint || isUpdatingPoint;
    const [draggedCoordinates, setDraggedCoordinates] = useState([]);
    const markerRef = useRef(null);
    const hoveredId = hoveredResult && hoveredResult.id;

    useEffect(() => {
        if (isUpdatingLine) {
            const updatedCoordinates = data.find((item) => item.id === updatingId)?.geometry.coordinates.map(coord => [coord[1], coord[0]]) || [];
            setDraggedCoordinates(updatedCoordinates);
            handleUpdatedCoordinates(updatedCoordinates);
        }
    }, [updatingId, data, isUpdatingLine]);

    useEffect(() => {
        HoverPopupClick(hoveredResult, markerRef);
    }, [hoveredResult]);

    const handleMarkerDragEnd = (index, event) => {
        const { target } = event;
        if (!target || !target._latlng) return;
        let { lat, lng } = target._latlng;
        let minDistance = { value: Number.MAX_VALUE };
        let closestMarker = { value: null };
        const snapDistanceThreshold = 0.00005;

        [kummer, noder].forEach(data => {
            data.forEach(marker => {
                const { latitude: markerLat, longitude: markerLng } = marker.coordinates;
                const distance = Math.sqrt(Math.pow(markerLat - lat, 2) + Math.pow(markerLng - lng, 2));
                //Node check
                if (!FindIfFullNode(marker) && distance <= snapDistanceThreshold && distance < minDistance.value) {
                    minDistance.value = distance;
                    closestMarker.value = marker;
                }
                //ConnectionPoint check
                else if (marker.type === "Kum" && distance <= snapDistanceThreshold && distance < minDistance.value) {
                    minDistance.value = distance;
                    closestMarker.value = marker;
                }
            });
        });
        if (!closestMarker.value) {
            [cables, pipes, homeownerAssocs].forEach(dataArray => {
                dataArray.forEach(data => {
                    if (!data.geometry || !data.geometry.coordinates) {
                        console.log(`No valid geometry found for data object.`);
                        return;
                    }
                    const {coordinates, type} = data.geometry;
                    if (type === "LineString") {
                        ProcessCoordinates(coordinates, minDistance, snapDistanceThreshold, closestMarker, lat, lng, data);
                    } else if (type === "Polygon") {
                        coordinates.forEach(ring => ProcessCoordinates(ring, minDistance, snapDistanceThreshold, closestMarker, lat, lng, data));
                    }
                });
            });
        }
        if (target) {
            const { _latlng } = target;
            if (_latlng) {
                const { lat, lng } = _latlng;
                let snappedLat = lat;
                let snappedLng = lng;
                let minDistance = Number.MAX_VALUE;
                let closestMarker = null;
                const snapDistanceThreshold = 0.00005;

                [kummer, noder].forEach(data => {
                    data.forEach(marker => {
                        const { latitude: markerLat, longitude: markerLng } = marker.coordinates;
                        const distance = Math.sqrt(Math.pow(markerLat - lat, 2) + Math.pow(markerLng - lng, 2));

                        if (!FindIfFullNode(marker) && distance <= snapDistanceThreshold && distance < minDistance) {
                            minDistance = distance;
                            closestMarker = marker;
                        }
                    });
                });
                if (closestMarker && closestMarker.id !== updatingId) {
                    snappedLat = closestMarker.coordinates.latitude;
                    snappedLng = closestMarker.coordinates.longitude;
                }
                const newCoordinates = [...draggedCoordinates];
                if (target.options.isMidMarker) {
                    const nextIndex = index;

                    const newCoordinate = [snappedLat, snappedLng];

                    newCoordinates.splice(nextIndex, 0, newCoordinate);
                } else {
                    newCoordinates[index] = [snappedLat, snappedLng];
                }
                setDraggedCoordinates(newCoordinates);
                setDraggedCoordinates(newCoordinates);
                handleUpdatedCoordinates(newCoordinates);
            }
        }
        if (closestMarker.value && closestMarker.value.data.id !== updatingId) {
            const snappedLat = closestMarker.value.coordinates.latitude;
            const snappedLng = closestMarker.value.coordinates.longitude;
            target.setLatLng([snappedLat, snappedLng]);
            const snapMarkerData = closestMarker.value.data ? closestMarker.value.data.properties : closestMarker.value;
            const name = snapMarkerData.name;
            const type = snapMarkerData.nodeType ? snapMarkerData.nodeType : (snapMarkerData.kumType ? snapMarkerData.kumType : snapMarkerData.type);
            const message = `Snappet til ${type === "Borettslag" ? 'borettslaget' : ''} ${name}${type !== "Borettslag" ? `(${type})` : ''}`;
            setSnapMessage(message);
            lat = snappedLat;
            lng = snappedLng;
        }
        const newCoordinates = [...draggedCoordinates];
        if (target.options.isMidMarker) {
            newCoordinates.splice(index, 0, [lat, lng]);
        } else {
            newCoordinates[index] = [lat, lng];
        }
        setDraggedCoordinates(newCoordinates);
        handleUpdatedCoordinates(newCoordinates);
    }

    const filteredData = data.filter((item) => {
        const allRenderResult = AllRenderFilter(layerFilters, item);
        const lineRenderResult = LineRenderFilter(layerFilters, item);
        if (hoveredId === item.id) {
            return true;
        }
        return allRenderResult && lineRenderResult;
    });

    return (
        <LayerGroup>
            {filteredData.map((item) => (
                <React.Fragment key={item.id}>
                    {isUpdating(item) && (
                        <UpdatingObject
                            draggedCoordinates={draggedCoordinates}
                            setDraggedCoordinates={setDraggedCoordinates}
                            handleUpdatedCoordinates={handleUpdatedCoordinates}
                            handleMarkerDragEnd={handleMarkerDragEnd}
                            type="line"
                            style={style}
                        />
                    )}
                    {!isUpdating(item) &&
                        (
                            <>
                                <GeoJSON
                                    data={item}
                                    style={style}
                                    item={item}
                                    ref={hoveredId === item.id ? markerRef : null}
                                >
                                    {!isAdding && (
                                        <Popup position={findMarkerCoordinate(item)}>
                                            <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                        </Popup>
                                    )}
                                    {movingPoint &&
                                        item.geometry.coordinates.map((coord, coordIndex) => (
                                        <Marker
                                            key={`add-point-${coordIndex}`}
                                            position={[coord[1], coord[0]]}
                                            icon={icons["eachCoordinate"]}
                                        />
                                    ))}
                                </GeoJSON>

                                {showIcon(item) && (
                                    <Marker position={findMarkerCoordinate(item)} icon={icon}>
                                        {!isAdding && (
                                            <Popup position={findMarkerCoordinate(item)}>
                                                <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                            </Popup>
                                        )}
                                    </Marker>
                                )}
                            </>
                        )
                    }
                </React.Fragment>
            ))}
        </LayerGroup>
    );
};

export default RenderLines;









