import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import MapPage from './pages/private/MapPage';
import LoginPage from "./pages/public/LoginPage";
import KumInfoPage from "./pages/private/infoPages/KumInfoPage";
import NodeInfoPage from "./pages/private/infoPages/NodeInfoPage";
import HomeownerAssocInfoPage from "./pages/private/infoPages/HomeownerAssocInfoPage";
import PipeInfoPage from "./pages/private/infoPages/PipeInfoPage";
import CableInfoPage from "./pages/private/infoPages/CableInfoPage";
import ProfilePage from "./pages/private/ProfilePage";
import ListPage from "./pages/private/ListPage";
import NotFoundPage from "./pages/public/404";
import CheckToken from "./functions/apiCalls/CheckToken";
import GetUserType from "./functions/apiCalls/Get/GetUserType";
import ChatPage from "./pages/private/ChatPage";


const App = () => {
    let location = window.location.href;
    const baseUrl = process.env.REACT_APP_PUBLIC_URL;
    const token = sessionStorage.getItem("token");
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const fetchUserType = async () => {
            const type = await GetUserType();
            if (type) {
                setUserType(type);
            }
        }
        if (token && !userType) {
            fetchUserType();
        }
    }, []);

    useEffect(() => {
        if (!location.endsWith('/') && !location.endsWith('/404')) {
            if (!token) {
                window.location.href = '/';
            } else {
                CheckToken();
            }
        }
        if (token && location === baseUrl + '/') {
            window.location.href = '/kart';
        }
        if (token && userType && userType === "Rådgiver Read Only") {
            if (!location.endsWith('/kart')) {
                window.location.href = '/kart';
            }
        }
    }, [location, token]);

    useEffect(() => {
        if (token) {
            const now = new Date();
            const lastCheck = new Date(sessionStorage.getItem("lastCheckToken") || 0);
            if (now.toDateString() !== lastCheck.toDateString()) {
                CheckToken();
                sessionStorage.setItem("lastCheckToken", now);
            }
            const handleFirstClick = () => {
                const currentTime = new Date();
                const lastCheckClick = new Date(sessionStorage.getItem("lastCheckToken") || 0);

                if (currentTime.toDateString() !== lastCheckClick.toDateString()) {
                    CheckToken();
                    sessionStorage.setItem("lastCheckToken", currentTime);
                }
            };
            window.addEventListener('click', handleFirstClick);
            return () => window.removeEventListener('click', handleFirstClick);
        }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/kart" element={<MapPage />} />
                <Route path="/liste" element={<ListPage />} />
                <Route path="/profil" element={<ProfilePage  />} />
                <Route path="/chat" element={<ChatPage  />} />

                <Route path="/kum/:id" element={<KumInfoPage />} />
                <Route path="/node/:id" element={<NodeInfoPage />} />
                <Route path="/kabel/:id" element={<CableInfoPage />} />
                <Route path="/ror/:id" element={<PipeInfoPage />} />
                <Route path="/borettslag/:id" element={<HomeownerAssocInfoPage />} />

                {/* Catch all other routes and redirects to 404 page */}
                <Route path="/*" element={<Navigate to="/404" />} />
                <Route path="/404" element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
};

export default App;
