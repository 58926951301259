import {CancelUpdateCoordinates, HandleMatchingNodes} from "../../functions/map/updateCoordinates/updateCoordinates";
import React from "react";

const MapButtons = ({isAddingPoint, isAddingLine, isAddingPoly, isUpdatingPoint, isUpdatingLine, isUpdatingPolygon, choosePort, updatingId, updatedCoordinates, cables, noder, setChoosePort, setChoosePortCable, setChoosePortNode, setSaveAble, setIsUpdatingPoint, setIsUpdatingLine, setUpdatedCoordinates, setIsUpdatingPolygon, setUpdatingId, setCurrentPosition}) => {
    const ScrollDown = () => {
        const targetElement = document.getElementById('bottom');
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        } else {
            console.error("Target element not found");
        }
    };

    const GetCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setCurrentPosition([latitude, longitude]);
            },
            (error) => {
                console.error('En feil oppsto under henting av koordinatene:', error);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
    };

    return (
        <>
            {((isAddingPoly) || (isAddingLine) || (isAddingPoint) ) && (
                <div className="map--update-coordinate--buttons flex--row justify-content-sb">
                    <button className="btn btn-primary" onClick={ScrollDown} >Scroll ned til skjema</button>
                    {isAddingPoint && (
                        <button className="btn btn-secondary" onClick={GetCurrentPosition}>Bruk nåværende
                            posisjon</button>
                    )}
                </div>
            )}
            {(isUpdatingPoint || isUpdatingLine || isUpdatingPolygon) && !choosePort && (
                <div className="flex--row justify-content-sb">
                    <div className="map--update-coordinate--buttons flex--row">
                        <button className="btn btn-secondary"
                                onClick={() => HandleMatchingNodes(updatingId, updatedCoordinates, cables, noder, setChoosePort, setChoosePortCable, setChoosePortNode, setSaveAble)}>Lagre
                        </button>
                        {isUpdatingPoint && (
                            <button className="btn btn-primary" onClick={GetCurrentPosition}>Bruk nåværende
                                posisjon</button>
                        )}
                        <button className="btn btn-delete"
                                onClick={() => CancelUpdateCoordinates(isUpdatingPoint, isUpdatingLine, isUpdatingPolygon, setIsUpdatingPoint, setIsUpdatingLine, setUpdatedCoordinates, setIsUpdatingPolygon, setUpdatingId)}>Avbryt
                        </button>
                    </div>
                    {(isUpdatingLine || isUpdatingPolygon) && (
                        <div className="map--update-coordinate--help-box flex--column align-items--center justify-content-center">
                            <div className="map--update-coordinate--help-box-text">
                                <p>Klikk og hold på de hvite sirklene for å flytte et koordinat. For å slette et koordinat, høyreklikk mens du holder inne venstre Shift-tast (↑).</p>
                            </div>
                            <h4 className="flex--row align-items--center justify-content-center"
                            >?</h4>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
export default MapButtons;