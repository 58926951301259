const GroupedPorts = ({ portsList }) => {
    const groupedPorts = portsList.reduce((acc, port) => {
        let switchIndex;
        let portNumber;
        if (port.switchIndex && port.portNumber) {
            switchIndex = port.switchIndex - 1;
            portNumber = port.portNumber;
        }
        else {
            [switchIndex, portNumber] = port.value.split(",");
        }
        if (!acc[switchIndex]) {
            acc[switchIndex] = [];
        }
        acc[switchIndex].push(portNumber);
        return acc;
    }, {});
    return groupedPorts;
};

export default GroupedPorts;
