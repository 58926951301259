import React, {useState} from "react";
import SendFeedbackForm from "./feedback/SendFeedbackForm";
import UserViewFeedback from "./feedback/UserViewFeedback";
import ProfileNavButton from "../../buttons/ProfileNavButton";

const FeedbackDisplay = () => {
    const [activeForm, setActiveForm] = useState('sendFeedback');

    const toggleForm = (form) => {
        setActiveForm(prevForm => (prevForm === form ? 'sendFeedback' : form));
    };

    const buttonData = [
        { label: 'Send Tilbakemelding', form: 'sendFeedback' },
        { label: 'Dine Sendte Tilbakemeldinger', form: 'viewFeedback' },
    ];
    return (
        <div className="profile--display-page flex--column">
            <div
                className="flex--row align-items--center justify-content-se profile--display-header profile--feedback-nav">
                {buttonData.map(({ label, form }) => (
                    <ProfileNavButton
                        key={form}
                        color={'green'}
                        label={label}
                        isActive={activeForm === form}
                        onClick={() => toggleForm(form)}
                    />
                ))}
            </div>
            <div>
                {activeForm === 'sendFeedback' && <SendFeedbackForm/>}
                {activeForm === 'viewFeedback' && <UserViewFeedback/>}
            </div>
        </div>
    );
}
export default FeedbackDisplay;