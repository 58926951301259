import React, {useEffect, useState} from "react";
import GetUserType from "../../functions/apiCalls/Get/GetUserType";
import apiUrls from "../../configs/api";
import GetAll from "../../functions/apiCalls/Get/GetAll";
import DeletePopup from "../messagesAndPopups/DeletePopup";
import {DetermineApiUrls, DetermineRedirectUrl} from "../../functions/fetches/DetermineUrls";

const ListDropdown = ({ id, icon, isOpen, toggleBox, items, header, setData, headers, paragraphs, showDeletePopup, setShowDeletePopup, connectionData, setRefresh, handleDelete, deleteItem }) => {
    const userId = sessionStorage.getItem('userId');
    const [userType, setUserType] = useState(null);
    const [apiUrl, setApiUrl] = useState('');
    const [urls, setUrls] = useState({});

    useEffect(() => {
        const fetchUserType = async () => {
            const fetchedUserType = await GetUserType();
            setUserType(fetchedUserType);
        };
        fetchUserType();
    }, []);

    useEffect(() => {
        if (!items || !paragraphs || !headers) {
            return;
        }
        const fetchUrls = async () => {
            const urlMap = {};
            for (let rowIndex = 0; rowIndex < items.length; rowIndex++) {
                for (let colIndex = 0; colIndex < paragraphs.length; colIndex++) {
                    const item = items[rowIndex];
                    const paragraphText = typeof paragraphs[colIndex] === "function"
                        ? paragraphs[colIndex](item)
                        : item[paragraphs[colIndex]];
                    const headerType = headers[colIndex]?.linkType;

                    if (!urlMap[rowIndex]) urlMap[rowIndex] = {};

                    urlMap[rowIndex][colIndex] = (paragraphText !== "Nei" && paragraphText !== "Ingen tilkoblet")
                        ? await getUrlByName(paragraphText, headerType)
                        : null;
                }
            }
            setUrls(urlMap);
        };
        fetchUrls();
    }, [items, paragraphs, headers]);


    const getUrlByName = async (itemName, type) => {
        let endpoint = "";
        switch (type) {
            case 'kum': endpoint = apiUrls.get.kummer; break;
            case 'node': endpoint = apiUrls.get.nodes; break;
            case 'kabel': endpoint = apiUrls.get.cables; break;
            case 'ror': endpoint = apiUrls.get.pipes; break;
            case 'borettslag': endpoint = apiUrls.get.homeownerAssocs; break;
            default: return '';
        }
        const response = await GetAll(endpoint);
        if (!response || !Array.isArray(response)) {
            console.log("Invalid response:", response);
            return '#';
        }
        if (response.length > 0) {
            const match = response.find(item => (item.properties ? item.properties.name : item.name) === itemName);
            if (match) {
                return `${type}/${match.id}`;
            }
        }
        return '#';
    };

    const buttonLock = (item) => {
        const editLock = item.properties ? item.properties.editLock : item.editLock;
        const editLockUser = item.properties ? item.properties.editorLockUserId : item.editorLockUserId;
        return editLock && (editLockUser !== userId && userType !== "Admin" && userType !== "Utvikler");
    };

    const handleDeleteClick = (item) => {
        handleDelete(item);
        setApiUrl(DetermineApiUrls(item));
        setShowDeletePopup(true);
    };

    return (
        <div id={id} className={`list--closeable ${isOpen[id] ? 'open' : ''} br flex--column`}>
            <div className="list--closeable-header flex--row align-items--center justify-content-sb" onClick={() => toggleBox(id)}>
                <h3 className="d-flex align-items--center">
                    <svg className={`icon--${icon}`} />
                    {header}
                </h3>
                <svg className="icon--black-chevron"/>
            </div>
            <div className="list--closeable-container flex--row justify-content-sb">
                {items && items.length > 0 ? (
                    <div className="flex--column">
                        <div className={`flex--row list--closeable-table-header ${userType !== "Rådgiver Read Only" ? '' : 'wide'}`}>
                            {headers && headers.map((header, index) => (
                                <p key={index} className={`w-${header.width}`}>
                                    <b>{header.label}</b>
                                </p>
                            ))}
                        </div>
                        <div className="list--closeable-table-data flex--column justify-content-sb">
                            {items.map((item, rowIndex) => (
                                <div key={rowIndex} className="flex--row">
                                    <div className={`flex--row list--closeable-table-data-row ${userType !== "Rådgiver Read Only" ? '' : 'wide'}`}>
                                        {paragraphs && paragraphs.map((paragraph, index) => {
                                            const value = paragraph.includes('.')
                                                ? paragraph.split('.').reduce((obj, key) => obj?.[key], item)
                                                : item[paragraph];

                                            const url = urls[rowIndex]?.[index];
                                            return headers[index].type === "link" && url ? (
                                                <a key={`${id}-a-${index}`} href={url} className={`w-${headers[index]?.width || 'default'}`}>
                                                    {value}
                                                </a>
                                            ) : (
                                                <p key={`${id}-p-${index}`} className={`w-${headers[index]?.width || 'default'}`}>
                                                    {value}
                                                </p>
                                            );
                                        })}
                                    </div>
                                    {userType !== 'Rådgiver Read Only' && (
                                        <div
                                            className={`flex--row align-items--center list--closeable-table-buttons ${userType !== "Read Only" ? "justify-content-sb" : "justify-content-right"}`}>
                                            <button
                                                className="btn btn-primary-white-bg"
                                                onClick={() => window.location.href = `/${DetermineRedirectUrl(item)}/${item.id}`}>
                                                Se mer
                                            </button>
                                            {userType !== 'Read Only' && (
                                                <button
                                                    className={`btn btn-delete-white-bg ${buttonLock(item) ? 'disabled' : ''}`}
                                                    onClick={() => handleDeleteClick(item)}
                                                    disabled={buttonLock(item)}
                                                >
                                                    Slett
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="list--closeable-container flex--row justify-content-sb">
                        <p>Ingen lagt til ennå!</p>
                    </div>
                )}
            </div>

            {showDeletePopup && apiUrl && (
                <DeletePopup
                    item={deleteItem}
                    connectionData={connectionData}
                    setShowDeletePopup={setShowDeletePopup}
                    apiUrlDelete={apiUrl}
                    redirect={false}
                    setData={setData}
                    setRefresh={setRefresh}
                />
            )}
        </div>
    );
}

export default ListDropdown;