import React, { useState } from 'react';
import SeeAllUsers from "./admin/SeeAllUsers";
import NewUserForm from "./admin/NewUserForm";
import EditUsersForm from "./admin/EditUsersForm";
import HandleDataDisplay from "./admin/HandleDataDisplay";

const AdminDisplay = () => {
    const [activeForm, setActiveForm] = useState('allUsers');

    return (
        <div className="profile--display-page with-nav flex--column">
            <div className="flex--row align-items--center justify-content-se profile--admin-nav-new">
                <button className={`${activeForm === "allUsers" ? 'active' : ''}`} type={"button"} onClick={() => setActiveForm("allUsers")}>Alle Brukere</button>
                <button className={`${activeForm === "addNewUser" ? 'active' : ''}`} type={"button"} onClick={() => setActiveForm("addNewUser")}>Opprett Ny Bruker</button>
                <button className={`${activeForm === "editUsers" ? 'active' : ''}`} type={"button"} onClick={() => setActiveForm("editUsers")}>Endre Brukere</button>
                <button className={`${activeForm === "handleData" ? 'active' : ''}`} type={"button"} onClick={() => setActiveForm("handleData")}>Håndter Data</button>
            </div>
            <div>
                {activeForm === 'allUsers' && <SeeAllUsers/>}
                {activeForm === 'addNewUser' && <NewUserForm/>}
                {activeForm === 'editUsers' && <EditUsersForm/>}
                {activeForm === 'handleData' && <HandleDataDisplay/>}
            </div>
        </div>
    );
};

export default AdminDisplay;