import React, { useEffect, useState } from 'react';
import InfoMessages from "../../messagesAndPopups/InfoMessages";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../configs/api";
import NewCableInputs from './Inputs/CableInputs';
import HandleInputChange from "../../../functions/inputs/HandleInputChange";
import AddMapObjectsIndexButtons from "../../buttons/AddMapObjectsIndexButtons";

const NewCableForm = ({ onSubmit, isAddingCable, setIsAddingCable, drawnCoordinates, setDrawnCoordinates, snappedNode, setSnappedNode }) => {
    const initialFormData = {
        name: '',
        status: 'I bruk',
        cableType: 'G2',
        selectedPipe: '',
        usePipesCoordinates: false,
        notes: '',
        selectedNode: null,
        connectToNode: false,
        portConnections: [],
    };
    const [formData, setFormData] = useState(initialFormData);
    const [pipesData, setPipesData] = useState([]);
    const [nodesData, setNodesData] = useState([]);
    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    const stepsCount = 3;
    const [messageData, setMessageData] = useState(null);
    const [inPipe, setInPipe] = useState(false);

    const handlePipeChange = (e) => {
        const value = e.target.value;
        setFormData(prevData => ({ ...prevData, selectedPipe: value }));
    };

    const handleNodeChange = (name, value) => {
        if (!formData.connectToNode) {
            return;
        }
        const selectedNode = nodesData.find(node => node.id === value);
        if (selectedNode) {
            setFormData(prevData => ({ ...prevData, selectedNode }));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const dataPipes = await GetAll(apiUrls.get.pipes);
            if (dataPipes) {
                setPipesData(dataPipes);
            }
            const dataNodes = await GetAll(apiUrls.get.nodes);
            if (dataNodes) {
                setNodesData(dataNodes);
            }
        };
        fetchData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, status, cableType, selectedPipe, usePipesCoordinates, notes, selectedNode, portConnections } = formData;
        let coordinates;
        if (usePipesCoordinates) {
            console.log(formData);
            const pipe = pipesData.find(pipe => pipe.id === selectedPipe);
            console.log(pipe);
            coordinates = pipe.geometry.coordinates.map(coord => [coord[1], coord[0]]);
        }
        else {
            coordinates = drawnCoordinates;
        }
        if (name && coordinates.length >= 2) {
            const cableData = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates,
                },
                properties: {
                    type: 'Kabel',
                    name: name,
                    status: status,
                    pipeId: (inPipe && selectedPipe !== '') ? selectedPipe : null,
                    cableType: cableType,
                    notes: notes,
                    selectedNode: selectedNode ? selectedNode.id : null,
                    portConnections: portConnections.length > 0 ? portConnections : null
                },
            };
            console.log(cableData);
            onSubmit(cableData);
            setFormData(initialFormData);
        } else {
            setMessageData({
                type: 'warning',
                message: 'Du må fylle inn alle felt og tegne inn minst 2 koordinater!'
            });
        }
    };

    const handleToggleDrawing = () => {
        setIsAddingCable(prev => !prev);
        setSnappedNode(null);
    };

    useEffect(() => {
        if (isAddingCable) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [isAddingCable]);

    useEffect(() => {
        if (!isAddingCable) {
            setDrawnCoordinates([]);
        }
    }, [isAddingCable, setDrawnCoordinates]);

    return (
        <div className="map--add-form flex--column justify-content-center">
            <div className="map--add-form-header flex--row justify-content-sb">
                <h3>Kabel</h3>
                <button
                    className={`btn btn-secondary-white-bg flex--row ${formData.usePipesCoordinates ? 'disabled' : ''} ${isAddingCable ? 'active' : ''}`}
                    onClick={handleToggleDrawing}
                    disabled={formData.usePipesCoordinates}
                >
                    {isAddingCable ? (
                        <>
                            Slett Linje <svg className="icon--green-edit"/>
                        </>
                    ) : (
                        formData.usePipesCoordinates ? (
                                <>
                                    Marker Linje <svg className="icon--green-edit"/>
                                </>
                            ): (
                                <>
                                    Marker Linje <svg className="icon--white-edit"/>
                                </>
                            )
                    )}
                </button>
                <div className="helping-box helping-box--draw">
                    {isAddingCable ? (
                        <p>Klikk for å slette markeringene gjort på kartet.</p>
                    ) : (
                        formData.usePipesCoordinates ? (
                            <p>
                                Trenger ikke å tegne kabelen, ettersom koordinatene hentes fra røret den er inni.
                            </p>
                            ) : (
                            <p>Klikk for å tegne kabelen på kartet. Høyreklikk for å avslutte tegningen uten å
                                slette.</p>
                        )
                    )}
                </div>
            </div>

            <form onSubmit={handleSubmit} className="flex--column justify-content-sb align-items--center">
                <div className="map--add-form-message">
                    {messageData && (
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    )}
                </div>

                <NewCableInputs handleInputChange={HandleInputChange} handlePipeChange={handlePipeChange}
                                currentGroupIndex={currentGroupIndex} pipesData={pipesData} formData={formData}
                                handleNodeChange={handleNodeChange} nodesData={nodesData} setFormData={setFormData}
                                snappedNode={snappedNode} inPipe={inPipe} setInPipe={setInPipe}
                />

                <AddMapObjectsIndexButtons currentGroupIndex={currentGroupIndex} setCurrentGroupIndex={setCurrentGroupIndex} stepsCount={stepsCount}/>
            </form>
        </div>
    );
};

export default NewCableForm;


