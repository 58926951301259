import FormatDate from "../../../functions/formats-and-regex/formatDate";
import React from "react";

const ProfileNotification = ({triggerUsers, notification, user, userIsTriggerUser}) => {
    return (
        <div
             className="profile--dashboard-notification flex--row justify-content-sb">
            <p className={`triggeruser ${userIsTriggerUser ? 'you' : ''} d-flex justify-content-center align-items--center`}>
                {triggerUsers[notification.triggerUserId]?.username === user.username ? "Du" : triggerUsers[notification.triggerUserId]?.username || "Laster..."}
            </p>
            <p className="message d-flex align-items--center">
                {notification.message}
            </p>
            <p className="date flex--row align-items--center">
                {FormatDate(notification.date)}
            </p>
        </div>
    );
}
export default ProfileNotification;